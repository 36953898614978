import { useLocalStorage } from 'shared/hooks';

export const useOnClickRecentSearchResults = <T extends string>(
  key: string,
  defaultState?: T[],
) => {
  const [recentSearches, setRecentSearches] = useLocalStorage<T[]>(
    key,
    {
      clearStorageOnLoad: false,
    },
    defaultState,
  );

  function deleteRecentSearch(searchToDelete: T) {
    if (!recentSearches) return;
    setRecentSearches(
      recentSearches?.filter(
        (search) => search.toLowerCase() !== searchToDelete.toLowerCase(),
      ),
    );
  }

  function handleSetRecentSearch(query: T) {
    if (!query) return;

    const lowerCaseRecentSearches = recentSearches?.map((search) =>
      search.toLowerCase(),
    );
    const queryExists = lowerCaseRecentSearches?.includes(query.toLowerCase());

    // If the new query doesn't exist, add it as the first element
    if (!queryExists) {
      recentSearches?.length
        ? setRecentSearches([query, ...recentSearches].slice(0, 3))
        : setRecentSearches([query]);
      return;
    }

    /**If query exists and it's not first element, move it to first position */
    if (
      queryExists &&
      lowerCaseRecentSearches?.[0] !== query.toLowerCase() &&
      recentSearches?.length
    ) {
      setRecentSearches([
        query,
        ...recentSearches.filter(
          (search) => search.toLowerCase() !== query.toLowerCase(),
        ),
      ]);
      return;
    }
  }

  return { recentSearches, handleSetRecentSearch, deleteRecentSearch };
};
