import { useFunction } from 'modules/firebase';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'sonner';
import { adminAction } from '../redux';

interface Values {
  email?: string;
  uid?: string;
}

export const useUsersAnalytics = () => {
  const getRegisteredUsers = useFunction<undefined, UserRecord[]>(
    'getRegisteredUsers',
  );
  const searchRegisteredUsers = useFunction<
    SearchRegisteredUsersData,
    SearchRegisteredUsersResponse
  >('searchRegisteredUsers');
  const dispatch = useDispatch();

  const searchUsers = async ({ email, uid }: Values) => {
    try {
      dispatch(adminAction.loading());

      const { data } = await searchRegisteredUsers({ email, uid });
      if (!data.success) throw new Error(data.message);

      dispatch(adminAction.clearUserToken());
      dispatch(adminAction.addUsersData(data.payload.users));
    } catch (error) {
      adminAction.error(error);
      toast.error('Failed to search users. Please try again.');
    }
  };

  const getUsers = useCallback(async () => {
    try {
      dispatch(adminAction.loading());

      const { data } = await getRegisteredUsers();
      if (!data.success) throw new Error(data.message);

      dispatch(adminAction.addUsersData(data.payload));
    } catch (error) {
      adminAction.error(error);
      toast.error('Failed to get users. Please try again.');
    }
  }, [getRegisteredUsers]);

  useEffect(() => {
    if (!getRegisteredUsers) return;
    getUsers();
  }, [getRegisteredUsers]);

  return { getUsers, searchUsers };
};
