import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { useMemo } from 'react';
import { toast } from 'sonner';
import { db } from '../initFirebase';
import { getTypedDocument, showDebugStatuses } from '../utils';

export function useFirestoreDocument<T extends { id?: string | null }>(
  path: string,
) {
  const reference = useMemo(() => doc(db, path), [path]);

  async function get() {
    try {
      const snapshot = await getDoc(reference);
      const doc = getTypedDocument<T>(snapshot);
      if (showDebugStatuses) console.log(`Fetched ${path}: `, doc);
      return doc;
    } catch (e) {
      toast.error(
        'Something went wrong. Please try again or contact customer support.',
      );
      return;
    }
  }

  async function set(data: Partial<T>) {
    try {
      delete data.id;
      await setDoc(reference, { ...data }, { merge: true });
    } catch (e) {
      toast.error(
        'Something went wrong. Please try again or contact customer support.',
      );
    }
  }

  function listen(
    onSucess: (doc: T | undefined) => void,
    onError: (error?: string) => void,
    onFinished?: VoidFunction,
  ) {
    return onSnapshot(
      reference,
      (snapshot) => {
        const doc = getTypedDocument<T>(snapshot);
        onSucess(doc);
        onFinished?.();
        if (showDebugStatuses) console.log(`Listening to ${path}: `, doc);
      },
      (error) => {
        onError(error.message);
        onFinished?.();
      },
    );
  }

  return { get, set, listen };
}
