import React from 'react';

interface Props {
  values: (string | null)[];
}

export const PublicClientAddressInfo: React.FunctionComponent<Props> = ({
  values,
}) => {
  const filteredValues = values.filter((value) => !!value);

  if (!Boolean(filteredValues.length)) return null;

  return <p className="text--xs__responsive">{filteredValues.join(', ')}</p>;
};
