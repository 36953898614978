import moreIcon from 'assets/icons/icon-more-primary-16.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { dashboardAction } from 'modules/dashboard';
import { useOverviewUpgradePlusModalOpen } from 'modules/proposals-overview';
import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'shared/components';
import { useAnimatedDropdown, useClickOutsideElement } from 'shared/hooks';

interface Props extends PropsWithChildren {
  className: string;
  btnSelector?: string;
  isPublishModalOpen?: boolean;
  editorClassName?: string;
}

export const ActionDrawer: React.FC<Props> = ({
  children,
  className,
  btnSelector,
  isPublishModalOpen,
  editorClassName,
}) => {
  const dispatch = useDispatch();
  const { isOpen, handleCloseDropdownToggle, handleOpenDropdownToggle } =
    useAnimatedDropdown();
  const isPlusUpgradeOpen = useOverviewUpgradePlusModalOpen();
  const drawerRef = useClickOutsideElement<HTMLDivElement>(
    handleCloseDropdownToggle,
    true,
    true,
  );
  const dropdownRef = isOpen && !isPlusUpgradeOpen ? drawerRef : null;

  useEffect(() => {
    isOpen
      ? dispatch(dashboardAction.success(isOpen))
      : setTimeout(() => dispatch(dashboardAction.success(isOpen)), 200);
  }, [isOpen]);

  return (
    <div ref={dropdownRef} className={editorClassName} data-cy={btnSelector}>
      <AnimatePresence>
        {isOpen && !isPublishModalOpen && (
          <motion.div
            className={className}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2, ease: [0.33, 1, 0.68, 1] }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
      <Button
        type="button"
        style="ghost"
        className="btn__sml__action-drawer
        btn__sml__action-drawer--iconOnly s-left--sml"
        onClick={isOpen ? handleCloseDropdownToggle : handleOpenDropdownToggle}
      >
        <img src={moreIcon} alt="MoreIcon" className="btn__icon" />
      </Button>
    </div>
  );
};
