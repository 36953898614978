import { adminAction } from './actions';

interface ReducerState {
  readonly isLoading: boolean;
  readonly userAnalytics: {
    pageToken: string[];
    users: UserRecord[];
  };
  readonly userReferrals?: ReferralAnalytics[];
  readonly proposalStats?: ProposalCounter<number>;
  readonly numberOfActiveUsersLastMonth: number;
  readonly error?: FirebaseAuthError;
}

const INITIAL_STATE: ReducerState = {
  isLoading: false,
  proposalStats: undefined,
  userReferrals: undefined,
  numberOfActiveUsersLastMonth: 0,
  userAnalytics: {
    pageToken: [''],
    users: [],
  },
  error: undefined,
};

export const adminReducer = (
  state: ReducerState = INITIAL_STATE,
  action: ActionUnion<typeof adminAction>,
): ReducerState => {
  switch (action.type) {
    case 'admin/LOADING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };

    case 'admin/ON_SNAPSHOT_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case 'admin/ADD_USERS_DATA':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        userAnalytics: { ...state.userAnalytics, users: action.payload.users },
      };

    case 'admin/ADD_PROPOSAL_STATS_DATA':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        proposalStats: action.payload.stats,
      };
    case 'admin/ADD_NUMBER_OF_ACTIVE_USERS_LAST_MONTH':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        numberOfActiveUsersLastMonth:
          action.payload.numberOfActiveUsersLastMonth,
      };
    case 'admin/ADD_USER_REFERRALS_DATA':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        userReferrals: action.payload.referrals,
      };
    case 'admin/ADD_USERS_TOKEN':
      return {
        ...state,
        isLoading: false,
        error: undefined,
        userAnalytics: {
          ...state.userAnalytics,
          pageToken: [
            ...state.userAnalytics.pageToken,
            action.payload.pageToken,
          ],
        },
      };
    case 'admin/RESET_ERRORS':
      return {
        ...state,
        error: undefined,
      };
    case 'admin/CLEAR_USER_TOKEN':
      return {
        ...state,
        userAnalytics: {
          ...state.userAnalytics,
          pageToken: INITIAL_STATE.userAnalytics.pageToken,
        },
      };
    case 'admin/LOGOUT':
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};
