import { Client, clientSelector, useClients } from 'modules/clients';
import {
  Proposal,
  ProposalUpdatePaths,
  useLazyProposalUpdate,
} from 'modules/proposals';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  ClientAddress,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import {
  useCloseOnEscape,
  useMobile,
  useModalGuard,
  useSubmitOnEnter,
} from 'shared/hooks';
import {
  ClientEmailInput,
  ClientNameInput,
  ClientVatIdInput,
  PhoneInput,
} from './fragments';

interface Props extends ModalProps {
  inputValue?: string;
  valueToUpdate?: ProposalUpdatePaths;
  item?: Client;
  inputSelector?: string;
}

export const ClientModal: React.FC<Props> = ({
  inputValue,
  valueToUpdate,
  item,
  inputSelector,
  onClose,
  ...rest
}) => {
  const isMobile = useMobile();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();

  const [nameInitialValue, setNameInitialValue] = useState('');

  const form = useForm<Client>({
    mode: 'onSubmit',
    defaultValues: new Client(item),
  });
  const context = useFormContext<Proposal>();
  const { setFormItem } = useLazyProposalUpdate<Client>({
    valueToUpdate,
    selector: clientSelector,
    valuesToCompare: ['name', 'email', 'vatId', 'phone'],
    callback: onClose,
  });

  const { handleClose } = useModalGuard();
  const { createEntityAsync, updateEntityAsync } = useClients();

  async function handleUpdate(client: Client) {
    const { id, ...rest } = client;
    if (id) await updateEntityAsync(id, rest);
  }

  async function handleCreate(client: Client) {
    const id = await createEntityAsync(client);
    if (!id || !context) return;

    context.setValue('client', { ...client, id }, { shouldDirty: true });
  }

  function handleCloseModal() {
    handleClose(
      form.getValues().name,
      nameInitialValue,
      onClose,
      form.formState.isDirty,
    );
  }

  function handleName(name: string) {
    setNameInitialValue(name);
  }

  async function saveData(data: Client) {
    const modifierFunction = item ? handleUpdate : handleCreate;
    await modifierFunction(new Client(data));

    context ? setFormItem(data) : onClose();
  }

  useEffect(() => {
    if (inputValue) form.setValue('name', inputValue);
  }, []);

  return (
    <Modal
      {...rest}
      isDashboardModal
      containerClassName="client__modal"
      close={handleCloseModal}
    >
      <FormProvider {...form}>
        <ModalHeader>
          <p className="text--xl__responsive s-bottom--sml">
            <FormattedMessage
              id={item ? 'buttons.edit-client' : 'buttons.client-information'}
            />
          </p>
        </ModalHeader>
        <ModalContent>
          <div className="client-modal__input-group">
            <div className="field__half--width client-modal__spacer">
              <ClientNameInput
                inputSelector="client-name-input"
                sendName={handleName}
                autoFocus={!isMobile}
              />
            </div>
            <div className="field__half--width">
              <ClientVatIdInput />
            </div>
          </div>
          <div className="client-modal__input-group">
            <div className="field__half--width client-modal__spacer">
              <ClientEmailInput />
            </div>
            <div className="field__half--width">
              <PhoneInput />
            </div>
          </div>

          <ClientAddress data-cy={inputSelector} />
        </ModalContent>
        <ModalFooter>
          <div className="btn-group btn-group--simple client-modal__btn__group">
            <Button
              type="button"
              ref={closeButtonRef}
              onClick={onClose}
              style="outline"
              size="lrg"
              btnSelector="modal-cancel-btn"
            >
              <FormattedMessage id="buttons.cancel" />
            </Button>
            <Button
              type="submit"
              onClick={form.handleSubmit(saveData)}
              ref={submitButtonRef}
              style="primary"
              size="lrg"
              btnSelector="save-modal-btn"
            >
              <FormattedMessage id="buttons.save" />
            </Button>
          </div>
        </ModalFooter>
      </FormProvider>
    </Modal>
  );
};
