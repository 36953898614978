import React from 'react';
import Select, { Props as SelectProps } from 'react-select';
import { OptionType, getSelectStyles } from './select.styles';

type StyledSelectProps<T> = SelectProps<OptionType<T>, false>;

export const StyledSelect = <T extends {}>(props: StyledSelectProps<T>) => (
  <Select
    styles={getSelectStyles<T>()}
    name={props.name}
    {...props}
    data-cy="btn"
  />
);
