import React from 'react';
import { TableHead } from 'shared/components';

export const UsersTable: React.FC<{ users: UserRecord[] }> = ({ users }) => {
  return (
    <div className="table-wrapper">
      <table className="table table--fixed">
        <colgroup>
          <col width="192"></col>
          <col width="192"></col>
          <col width="192"></col>
          <col width="192"></col>
          <col width="192"></col>
        </colgroup>
        <thead>
          <tr>
            <TableHead label="ID" />
            <TableHead label="Email" />
            <TableHead label="Date created" />
            <TableHead label="Last login date" />
            <TableHead label="Companies" />
          </tr>
        </thead>
        <tbody>
          {users?.map((user) => (
            <tr key={`user-${user.uid}`}>
              <td>{user.uid}</td>
              <td>{user.email}</td>
              <td>{user.metadata.creationTime}</td>
              <td>{user.metadata.lastSignInTime}</td>
              <td>
                {user?.customClaims?.roles
                  ?.map(
                    ({ role, organizationId }: RoleClaim) =>
                      `${role} - ${organizationId}`,
                  )
                  .join(', ')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
