import { useScrollIntoView } from 'modules/authentication';
import { useConfigSelector } from 'modules/settings';
import { onlyNumbersRegex } from 'modules/validation';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';
import { isZeroBeforeDot } from 'shared/utils';

interface Props {}

export const ProposalVatPercentageInput: React.FC<Props> = () => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const config = useConfigSelector();

  const {
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<PublishStatusAware<Proposal>>();

  useEffect(() => {
    if (!config?.tax?.vatPercentage || getValues('id')) return;
    setValue('vatPercentage', config?.tax?.vatPercentage);
  }, [config]);

  return (
    <>
      <FieldWrapper
        name="vatPercentage"
        errorClassName="s-top--sml g--column-span t-danger text--sm t-right"
        containerClassName="m-left-auto u-width--auto"
        isRequired
        errors={errors}
        hasPercentage
      >
        <Controller
          name="vatPercentage"
          control={control}
          rules={{
            min: {
              value: 0,
              message: formatMessage({
                id: 'inputs.vat-percentage.min-value-error',
              }),
            },
            max: {
              value: 100,
              message: formatMessage({
                id: 'inputs.vat-percentage.max-value-error',
              }),
            },
            pattern: {
              value: onlyNumbersRegex,
              message: formatMessage({ id: 'inputs.vat-percentage.invalid' }),
            },
            validate: (value) => {
              if (isZeroBeforeDot(String(value))) {
                return formatMessage({
                  id: 'inputs.vat-percentage.starts-with-zero',
                });
              }
              return;
            },
          }}
          defaultValue={getValues('vatPercentage')}
          render={({ field }) => (
            <input
              {...field}
              inputMode="decimal"
              type="number"
              className={`input input__box input__w__micro s-right--sml total-prices__vat__input ${
                errors.vatPercentage ? 'input__box__error' : ''
              }`}
              data-cy="builder-vat-percentage-input"
              onFocus={handleFocus}
            />
          )}
        />
      </FieldWrapper>
    </>
  );
};
