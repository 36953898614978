import { useEffect, useState } from 'react';

type Configuration = {
  /** Will clear key from local storage when it's loaded.
   * Use this for reading and automatically clearing sensitive data. */
  clearStorageOnLoad?: boolean;
};

export function useLocalStorage<T>(
  key: string,
  config?: Configuration,
  initialValue?: T,
): [T | null | undefined, (value: T | null) => void] {
  const { clearStorageOnLoad } = config || {};
  const isBrowser = typeof window !== 'undefined';
  const currentValue = isBrowser ? localStorage?.getItem(key) : null;
  const [storageState, setStorageState] = useState<T | null>(
    currentValue ? (JSON.parse(currentValue) as T) : initialValue || null,
  );

  const updateFunction = (value: T | null) => {
    if (isBrowser) {
      value
        ? localStorage.setItem(key, JSON.stringify(value))
        : localStorage.removeItem(key);
    }
    setStorageState(value);
  };

  useEffect(() => {
    if (storageState && clearStorageOnLoad) localStorage.removeItem(key);
  }, []);

  return [storageState, updateFunction];
}
