import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useCloseOnEscape } from 'shared/hooks';
import { SortOptions } from './SortOptions';

interface Props {
  sortOption: SortOption;
  onSetSortOption: (sortOption: SortOption) => void;
  onClose: () => void;
}

export const SortModal: React.FC<Props> = ({
  sortOption,
  onSetSortOption,
  onClose,
}) => {
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();

  return (
    <Modal close={onClose} isDashboardModal>
      <ModalHeader
        headerSelector="proposal-overview-filter-modal-header"
        isConfirmationModal
      >
        <p className="text--xl__responsive">
          <FormattedMessage id="proposals.overview.sort.mobile-title" />
        </p>
      </ModalHeader>

      <ModalContent contentSelector="proposal-sort-modal-content">
        <SortOptions
          sortOption={sortOption}
          onSetSortOption={onSetSortOption}
        />
      </ModalContent>
      <ModalFooter footerSelector="proposal-sort-modal-footer">
        <div className="btn-group">
          <Button
            type="button"
            onClick={onClose}
            ref={closeButtonRef}
            style="outline"
            size="lrg"
            className="u-width--full"
            btnSelector="proposal-sort-modal-cancel"
          >
            <FormattedMessage id="buttons.close" />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
