import { useFunction } from 'modules/firebase';
import { toast } from 'sonner';

export function usePublicProposal() {
  const declineCallable = useFunction<AcceptanceStatusData>('declineProposal');
  const acceptCallable = useFunction<AcceptanceStatusData>('acceptProposal');
  const suggestChangesCallable =
    useFunction<AcceptanceStatusData>('suggestChanges');
  const sendCodeCallable = useFunction<SendAuthorizationCodeData>(
    'sendAuthorizationCode',
  );
  const verifyCodeCallable = useFunction<VerifyAuthorizationCodeData>(
    'verifyAuthorizationCode',
  );
  const verifyAccessCallable =
    useFunction<VerifyAccessRightsData>('verifyAccessRights');

  const contactOwnerCallable = useFunction<ContactProposalAuthorData>(
    'contactProposalOwner',
  );
  const acceptanceStatusReset = useFunction<ResetAcceptanceStatusData>(
    'acceptanceStatusReset',
  );

  async function acceptProposal(params: AcceptanceStatusData) {
    try {
      if (!acceptCallable) throw new Error('Unreachable function');
      const { data } = await acceptCallable(params);
      toast.success('You have accepted the proposal.');
      if (!data.success) throw new Error(data.message);
    } catch (e) {
      toast.error(JSON.stringify(e));
    }
  }

  async function declineProposal(params: AcceptanceStatusData) {
    try {
      if (!declineCallable) throw new Error('Unreachable function');
      const { data } = await declineCallable(params);
      toast.success('You have declined the proposal.');
      if (!data.success) throw new Error(data.message);
    } catch (e) {
      toast.error(JSON.stringify(e));
    }
  }

  async function suggestChanges(params: AcceptanceStatusData) {
    try {
      if (!suggestChangesCallable) throw new Error('Unreachable function');
      const { data } = await suggestChangesCallable(params);
      toast.success('Your feedback has been sent to the proposal owner.');
      if (!data.success) throw new Error(data.message);
    } catch (e) {
      toast.error(JSON.stringify(e));
    }
  }

  async function sendAuthorizationCode(params: SendAuthorizationCodeData) {
    try {
      if (!sendCodeCallable) throw new Error('Unreachable function');
      const { data } = await sendCodeCallable(params);
      if (!data.success) throw new Error(data.message);
      return true;
    } catch (e) {
      toast.error(JSON.stringify(e));
      return false;
    }
  }

  async function verifyAuthorizationCode(params: VerifyAuthorizationCodeData) {
    try {
      if (!verifyCodeCallable) throw new Error('Unreachable function');
      const { data } = await verifyCodeCallable(params);
      if (!data.success) throw new Error(data.message);
      return true;
    } catch (e) {
      return false;
    }
  }

  async function contactProposalOwner(params: ContactProposalAuthorData) {
    try {
      if (!contactOwnerCallable) throw new Error('Unreachable function');
      const { data } = await contactOwnerCallable(params);
      if (!data.success) throw new Error(data.message);
      return true;
    } catch (e) {
      return false;
    }
  }

  async function verifyAccessRights(params: VerifyAccessRightsData) {
    try {
      if (!verifyAccessCallable) throw new Error('Unreachable function');
      const { data } = await verifyAccessCallable(params);
      if (!data.success) throw new Error(data.message);
      return true;
    } catch (e) {
      return false;
    }
  }

  async function resetAcceptanceStatus(params: ResetAcceptanceStatusData) {
    try {
      if (!acceptanceStatusReset) throw new Error('Unreachable function');
      const { data } = await acceptanceStatusReset(params);
      if (!data.success) throw new Error(data.message);
      return true;
    } catch (e) {
      return false;
    }
  }

  return {
    acceptProposal,
    declineProposal,
    suggestChanges,
    sendAuthorizationCode,
    verifyAuthorizationCode,
    verifyAccessRights,
    contactProposalOwner,
    resetAcceptanceStatus,
  };
}
