import isUrl from 'is-url';
import { CustomEditor, CustomElement } from 'shared/components';
import {
  Editor,
  Path,
  Point,
  Range,
  Element as SlateElement,
  Transforms,
} from 'slate';

/**Source: https://github.com/ianstormtaylor/slate/blob/main/site/examples/inlines.tsx */
export function withInlines(editor: CustomEditor) {
  const { insertData, insertText, isInline, isElementReadOnly, isSelectable } =
    editor;

  editor.isInline = (element) =>
    ['link', 'button', 'badge'].includes(element.type) || isInline(element);

  editor.isElementReadOnly = (element) => isElementReadOnly(element);

  editor.isSelectable = (element) => isSelectable(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
}

export function wrapLink(editor: CustomEditor, url: string, text?: string) {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: text || url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
}

export function editWrappedLink(
  editor: CustomEditor,
  url: string,
  text: string,
) {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;

  const isCollapsed = selection && Range.isCollapsed(selection);
  const selectedText = selection ? Editor.string(editor, selection) : '';

  if (selectedText !== text && text && selection) {
    Transforms.delete(editor, { at: selection });
    Transforms.insertText(editor, text);
  }

  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: text || url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
}

export function isLinkActive(editor: CustomEditor) {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return !!link;
}

export function unwrapLink(editor: CustomEditor) {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
}

export function insertLink(editor: CustomEditor, url: string, text?: string) {
  if (editor.selection) {
    wrapLink(editor, url, text);
  }
}

export function getLinkText(linkElement: CustomElement) {
  if (linkElement.type === 'link' && Array.isArray(linkElement.children)) {
    return linkElement.children.map((child) => child.text).join('');
  }
  return '';
}

export function isLinkHttpUrl(url: string) {
  return url.startsWith('http://') || url.startsWith('https://');
}

export function ensureHttpUrl(url: string) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
}

export function closeLinkTooltip(editor: CustomEditor) {
  if (!editor.selection) return;

  const [linkNode, linkPath] =
    Editor.above(editor, {
      match: (n) => SlateElement.isElement(n) && n.type === 'link',
    }) || [];
  if (!linkNode || !linkPath) return;

  const endOfLink = Editor.end(editor, linkPath);
  if (Point.equals(Editor.point(editor, editor.selection.focus), endOfLink)) {
    const newBlock: ParagraphElement = {
      type: 'paragraph',
      children: [{ text: '' }],
    };
    Transforms.insertNodes(editor, newBlock, {
      at: Path.next(linkPath),
    });

    Transforms.select(editor, Editor.start(editor, Path.next(linkPath)));
    return;
  }
}
