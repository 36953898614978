import { useParams } from '@reach/router';
import { authenticationSelector } from 'modules/authentication';
import {
  useNotifyOwner,
  useNotifyOwnerTimestamp
} from 'modules/proposals';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Button,
  LoadingSpinner,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import {
  useCloseOnEscape,
  useSubmitOnEnter,
  useSupportMail,
} from 'shared/hooks';

interface Props {
  proposalId?: string;
  onSetError: (error: boolean) => void;
  onClose: VoidFunction;
}

export const ProposalExpiredActionModal: React.FC<Props> = ({
  proposalId,
  onSetError,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const supportMail = useSupportMail();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();

  const { user } = useSelector(authenticationSelector.getState);
  const { organizationId = '' } = useParams() || {};

  const [email, setEmail] = useState(user?.email || '');
  const [error, setError] = useState<string>();

  const { loading, notifyOwner } = useNotifyOwner(
    organizationId,
    email,
    proposalId,
    onSetError,
  );

  const { handleSubmit } = useNotifyOwnerTimestamp(
    email,
    notifyOwner,
    setError,
  );

  function handleError(value: string) {
    if (error) setError(undefined);
    setEmail(value);
  }

  return (
    <Modal isDashboardModal>
      <ModalHeader>
        <p className="text--xl__responsive">
          <FormattedMessage id="proposal-expired.action-modal.title" />
        </p>
      </ModalHeader>
      <ModalContent>
        <p className="t-text-2 text--xs__responsive proposal-expired-modal__description u-maxwidth--432">
          <FormattedMessage id="proposal-expired.email.description" />
        </p>
        <div className="f f--col">
          <p
            className={`s-bottom--tny t-label t-text-2 ${
              error && 't-label--error'
            }`}
          >
            <FormattedMessage id="proposal-expired.email.label" />*
          </p>
          <input
            className={`input input__med input__box ${
              error && 'input__box__error'
            }`}
            type="email"
            data-cy="notifiy-modal-email-input"
            value={email}
            disabled={loading}
            onChange={({ currentTarget: { value } }) => handleError(value)}
            placeholder={formatMessage({
              id: 'inputs.email.placeholder',
            })}
          />
          {error && <p className="t-small t-danger s-top--tny">{error}</p>}
        </div>
        <p className="t-text-3 t-small s-top--med u-maxwidth--520 proposal-expired__description">
          <FormattedMessage
            id="proposal-expired.action.description"
            values={{
              email: (
                <a href={supportMail}>
                  <Button type="button" style="text" className="t-primary">
                    <FormattedMessage id="proposal-expired.action.mail.link" />
                  </Button>
                </a>
              ),
            }}
          />
        </p>
      </ModalContent>
      <ModalFooter>
        <div className="btn-group btn-group--simple">
          <Button
            type="button"
            ref={closeButtonRef}
            onClick={onClose}
            size="lrg"
            style="outline"
          >
            <FormattedMessage id="proposal-expired.action-modal.cancel__btn" />
          </Button>
          {loading ? (
            <Button
              type="button"
              disabled={loading}
              size="lrg"
              style="primary"
              className="proposal-expired-modal-action__btn"
            >
              <LoadingSpinner height={12} width={12} type="negative" />
              <p className="s-left--med">
                <FormattedMessage id="subscription-action-controller.action.downgrading" />
              </p>
            </Button>
          ) : (
            <Button
              type="button"
              ref={submitButtonRef}
              onClick={handleSubmit}
              size="lrg"
              style="primary"
              className="proposal-expired-modal-action__btn"
              btnSelector="send-notification-btn"
            >
              <FormattedMessage id="proposal-expired.action__btn" />
            </Button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};
