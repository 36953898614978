import { currencyOptions } from 'modules/proposals';
import React, { useCallback, useMemo } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select, { SingleValue } from 'react-select';
import { FieldWrapper, getSelectStyles } from 'shared/components';
import { useMobile } from 'shared/hooks';

export const CurrencySelect: React.FC = ({}) => {
  const isMobile = useMobile();
  const { formatMessage } = useIntl();

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<PublishStatusAware<Proposal>>();

  const handleCurrencyChange = useCallback(
    (field: ControllerRenderProps<PublishStatusAware<Proposal>, 'currency'>) =>
      (
        selectedOption: SingleValue<{
          value: string;
          label: string;
        }>,
      ) => {
        field.onChange(selectedOption?.value);
      },
    [],
  );

  const options = useMemo(
    () =>
      currencyOptions?.map((item) => ({
        value: item,
        label: item,
      })),
    [currencyOptions],
  );

  function getValue(data: string | null) {
    if (!data) return null;

    return {
      value: data,
      label: data,
    };
  }

  return (
    <div data-cy="currency-dropdown">
      <FieldWrapper
        name="currency"
        labelId="dropdowns.currency.label"
        isRequired
        errors={errors}
      >
        <Controller
          name="currency"
          control={control}
          rules={{
            required: formatMessage({ id: 'dropdowns.currency.required' }),
          }}
          defaultValue={getValues('currency') || null}
          render={({ field }) => (
            <Select
              {...field}
              options={options}
              onChange={handleCurrencyChange(field)}
              value={getValue(field.value)}
              styles={getSelectStyles<string>(Boolean(errors.currency), 124)}
              id="currency__styled__select"
              className="select__currency--input"
              placeholder={formatMessage({
                id: 'dropdowns.currency.placeholder',
              })}
              isSearchable={!isMobile}
            />
          )}
        />
      </FieldWrapper>
    </div>
  );
};
