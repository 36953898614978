import { useFirestoreEntitites } from 'modules/firebase';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'sonner';
import { authorizationSelector } from '../redux';
import { useOrganizationIdSelector } from '../state';

export function useAuthorization() {
  const organizationId = useOrganizationIdSelector();
  const { roles } = useSelector(authorizationSelector.getState);

  const entityFunctions = useFirestoreEntitites<OrganizationUser>(
    'roles',
    organizationId,
  );

  const isModerator = useCallback(
    () => Boolean(roles?.find((item) => item.role === 'moderator')),
    [],
  );

  const isOwner = useCallback(
    () => Boolean(roles?.find((item) => item.role === 'owner')),
    [],
  );

  async function createEntityAsync(data: OrganizationUser) {
    try {
      entityFunctions.createEntityAsync(data);
      toast.success(
        `User invite has been sent. We will update the status when they accept the invite.`,
      );
    } catch (error) {
      toast.error(`User invite failed. Try again later.`);
    }
  }

  async function updateEntityAsync(entityId: string, data: OrganizationUser) {
    try {
      await entityFunctions.updateEntityAsync(entityId, data);
      toast.success(`User has been successfully updated.`);
    } catch (error) {
      toast.error(`User update failed. Try again later.`);
    }
  }

  async function deleteEntityAsync(entityId: string) {
    try {
      await entityFunctions.deleteEntityAsync(entityId);
      toast.success(`User has successfully been deleted.`);
    } catch (error) {
      toast.error(`We couldn't delete the user. Try again later.`);
    }
  }

  return {
    ...entityFunctions,
    deleteEntityAsync,
    createEntityAsync,
    updateEntityAsync,
    isModerator,
    isOwner,
  };
}
