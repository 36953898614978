import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  iconPath: string;
  messageId: string;
  iconClassName?: string;
  tooltipClassName?: string;
  onClick: VoidFunction;
}

export const Tooltip: React.FC<Props> = ({
  iconPath,
  messageId,
  iconClassName,
  tooltipClassName,
  onClick,
}) => {
  return (
    <div className="tooltip__container">
      <img
        src={iconPath}
        alt="Icon"
        className={`${iconClassName || ''} tooltip__icon`}
        onClick={onClick}
      />
      <span className={tooltipClassName}>
        <FormattedMessage id={messageId} />
      </span>
    </div>
  );
};
