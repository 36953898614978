import addIcon from 'assets/icons/icon-company-logo-upload-24.svg';
import deleteIcon from 'assets/icons/icon-trash-16-negative.svg';
import { firebaseAtoms } from 'modules/firebase';
import React, { useCallback, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { Button, ConfirmationModal, FieldWrapper } from 'shared/components';
import { useModal } from 'shared/hooks';

const sizeLimitInBytes = 2 / 10 ** -6;
const accept = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
};

export const CompanyLogoPicker: React.FC = () => {
  const { formatMessage } = useIntl();
  const { isModalOpen, closeModal, openModal } = useModal();

  const [uploadError, setUploadError] = useState<string | undefined>();
  const [blobUrlCache, setBlobUrlCache] = useRecoilState(
    firebaseAtoms.blobUrlCache,
  );

  const {
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext<Settings>();

  function handleSubmit() {
    setValue('companyImage', null, {
      shouldDirty: true,
    });
    setBlobUrlCache((prevBlobUrlCache) => ({
      ...prevBlobUrlCache,
      companyImage: null,
    }));
    closeModal();
  }

  const onDropAccepted = useCallback(
    (files: File[]) => {
      const fileUrl = URL.createObjectURL(files[0]);
      setBlobUrlCache((prevBlobUrlCache) => ({
        ...prevBlobUrlCache,
        companyImage: fileUrl,
      }));
      setUploadError(undefined);
      setValue('companyImage', fileUrl, {
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const onDropRejected = useCallback((rejections: FileRejection[]) => {
    if (
      !['image/jpeg', 'image/png', 'image.jpeg'].includes(
        rejections[0].file.type,
      )
    ) {
      rejections[0].errors[0].message = formatMessage({
        id: 'uploaders.errors.fileInvalidType',
      });

      setUploadError(
        formatMessage({ id: 'uploaders.errors.fileInvalidType' }, { size: 2 }),
      );
    }

    if (rejections[0].file.size > sizeLimitInBytes) {
      rejections[0].errors[0].message = formatMessage(
        { id: 'uploaders.errors.fileTooLarge' },
        { size: 2 },
      );

      setUploadError(
        formatMessage({ id: 'uploaders.errors.fileTooLarge' }, { size: 2 }),
      );
    }
  }, []);

  return (
    <>
      <FieldWrapper name="companyImage" errors={errors}>
        <div className="logofield s-top--lrg">
          <Controller
            control={control}
            name="companyImage"
            defaultValue={getValues('companyImage') || null}
            render={({ field: { ref, ...field } }) => (
              <>
                <Dropzone
                  {...field}
                  accept={accept}
                  maxSize={sizeLimitInBytes}
                  maxFiles={1}
                  onDropAccepted={onDropAccepted}
                  onDropRejected={onDropRejected}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div className="u-pos--rel">
                      <div
                        {...getRootProps()}
                        className={`logofield__uploader ${
                          uploadError ? 'logofield__uploader--error' : ''
                        }`}
                      >
                        <div className="dropzone" data-cy="dropzone-image">
                          <input {...getInputProps()} />

                          {getValues('companyImage') ? (
                            <div
                              className="dropzone__image"
                              style={{
                                backgroundImage: `url(${
                                  blobUrlCache['companyImage'] ||
                                  getValues('companyImage') ||
                                  ''
                                })`,
                              }}
                            />
                          ) : isDragActive ? (
                            <p>
                              <FormattedMessage id="uploaders.company-image.drag" />
                            </p>
                          ) : (
                            <div className="dropzone__uploadmessage">
                              <img src={addIcon} alt="AddIcon" />
                            </div>
                          )}
                        </div>
                      </div>

                      {getValues('companyImage') && (
                        <Button
                          type="button"
                          style="primary"
                          size="sml"
                          onClick={openModal}
                          className="photobox__delete"
                          btnSelector="company-image-delete-btn"
                        >
                          <img src={deleteIcon} alt="DeleteIcon" />
                        </Button>
                      )}
                    </div>
                  )}
                </Dropzone>

                <div className="f f--col logofield__help__wrapper">
                  <span className="text--sm t-bold t-text-1">
                    <FormattedMessage
                      id="uploaders.company-image.title"
                      values={{
                        optional: (
                          <span className="t-label t-text-3 t-normal">
                            <FormattedMessage id="uploaders.company-image.optional" />
                          </span>
                        ),
                      }}
                    />
                  </span>
                  <p className="text--xsm t-text-3 u-maxwidth--220">
                    <FormattedMessage id="uploaders.company-image.help" />
                  </p>
                </div>
              </>
            )}
          />
          <></>
        </div>{' '}
        {uploadError && (
          <p className="t-danger text--sm s-top--med">{uploadError}</p>
        )}
      </FieldWrapper>
      {isModalOpen && (
        <ConfirmationModal
          onClose={closeModal}
          onSubmit={handleSubmit}
          title={formatMessage({
            id: 'uploaders.company-image.remove-modal.title',
          })}
          subtitle={formatMessage({
            id: 'uploaders.company-image.remove-modal.subtitle',
          })}
          confirmationButtonLabel={formatMessage({
            id: 'uploaders.company-image.remove-modal.confirm',
          })}
          declineButtonLabel={formatMessage({
            id: 'uploaders.company-image.remove-modal.cancel',
          })}
          type="delete"
        />
      )}
    </>
  );
};
