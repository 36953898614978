import {
    Settings,
    useSettings,
    useSubscribeToNewsletter,
} from 'modules/settings';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';

export function useTaxActions() {
  const { formatMessage } = useIntl();
  const { updateOnOnboardingAsync } = useSettings();
  const { subscribeToNewsletter } = useSubscribeToNewsletter();

  function showError(error: unknown) {
    let errorMessage = formatMessage({ id: 'toasts.login.error' });
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    toast.warning(errorMessage);
  }

  async function onOnboardFinish(data: Settings) {
    try {
      await updateOnOnboardingAsync(data);
      if (!data.hasUnsubscribedOnOnboarding) {
        await subscribeToNewsletter();
      }
    } catch (error) {
      showError(error);
    }
  }

  return { onOnboardFinish };
}
