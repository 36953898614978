export function getPriceCurrencyAndAmountString(
  amount?: number | null,
  currency?: string,
  ISOCountry?: string,
) {
  return new Intl.NumberFormat(ISOCountry || 'HR', {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    currencySign: 'accounting',
    localeMatcher: 'best fit',
  }).format(Number((amount || 0) / 100));
}
