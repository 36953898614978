import { addDays, isAfter } from 'date-fns';

export function useLastWeekUsers(sortedUsers: UserRecord[]) {
  const lastWeekDate = new Date(addDays(new Date(), -7));
  const lastWeekUsers = sortedUsers?.filter(({ metadata }) =>
    isAfter(new Date(metadata.creationTime).getTime(), lastWeekDate.getTime()),
  );

  return { lastWeekUsers };
}
