import { authenticationSelector } from 'modules/authentication';
import { useOrganizationIdSelector } from 'modules/authorization';
import { settingsSelector } from 'modules/settings';
import { useSelector } from 'react-redux';
import { getCountryISOFromName } from 'shared/utils';
import { toast } from 'sonner';
import { useStripeCustomerCallables } from './useStripeCustomerCallables';

/** Stripe customer related functions and helpers.
 * If using anything from useStripeCustomerCallables, @param functionsInitialised **MUST** be set,
 * otherwise you risk the callable function not being instantiated */

export function useStripeCustomer() {
  const organizationId = useOrganizationIdSelector();
  const { user } = useSelector(authenticationSelector.getState);
  const { config } = useSelector(settingsSelector.getState);

  const { createCustomer, functionsInitialised } = useStripeCustomerCallables();

  async function createStripeCustomer() {
    if (!createCustomer) return;

    try {
      const { data } = await createCustomer({
        email: user?.email || '',
        organizationId,
        address: {
          city: config?.address.city || '',
          country: getCountryISOFromName(config?.address.country) || '',
          line1: config?.address.addressOne || '',
          postal_code: config?.address.postalCode || '',
          line2: config?.address.addressTwo || '',
          state: config?.address.state || '',
        },
        name: config?.name || '',
      });
      if (!data.success) throw new Error(data.message);

      return data.payload.customerId;
    } catch (error) {
      toast.error(`Creating stripe customer failed. Try again later.`);
      return;
    }
  }

  return {
    createStripeCustomer,
    functionsInitialised,
  };
}
