import { useState } from 'react';

export interface UseModalHook {
  isModalOpen: boolean;
  openModal: VoidFunction;
  closeModal: VoidFunction;
  guardModalClose: (onClose: VoidFunction, message: string) => void;
}

export function useModal(defaultState?: boolean): UseModalHook {
  const [isModalOpen, setIsModalOpen] = useState(defaultState || false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function guardModalClose(onClose: VoidFunction, message: string) {
    const modalGuardMessage = confirm(message);
    if (modalGuardMessage) onClose();
  }

  return {
    isModalOpen,
    openModal,
    closeModal,
    guardModalClose,
  };
}
