import { useLocation } from '@reach/router';
import { format } from 'date-fns';
import { Link } from 'gatsby';
import { useOrganizationIdSelector } from 'modules/authorization';
import {
  AcceptanceStatusSelect,
  ActionDrawer,
  ActionDrawerButtons,
  Proposal,
  useProposalActions,
  useProposalExpiredSelector,
} from 'modules/proposals';
import {
  SubscriptionTierWrapper,
  subscriptionSelectors,
} from 'modules/subscription';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { ConfirmationModal } from 'shared/components';
import { useScrollPosition } from 'shared/hooks';
import { isLocationTypeGuard } from 'shared/utils';
import {
  ProposalExpiredTooltip,
  ProposalOverviewListItemStatus,
  ProposalOverviewListItemTitle,
} from './fragments';

interface Props {
  proposal: Proposal;
  index: number;
}

export const ProposalOverviewListItem: React.FC<Props> = ({
  proposal,
  index,
}) => {
  const location = useLocation();
  const { formatMessage } = useIntl();
  const scrollPosition = useScrollPosition();
  const organizationId = useOrganizationIdSelector();
  const isTierOne = useRecoilValue(subscriptionSelectors.isTierOne);
  const isExpiredNotificationVisible = useProposalExpiredSelector(
    proposal,
    isTierOne,
  );

  const {
    submit,
    onCancel,
    onUpdate,
    getPublishStatusModalProps,
    form,
    publishStatusModal,
    acceptanceStatusModal,
  } = useProposalActions(proposal);

  const { pathname, search } = location;
  const state = isLocationTypeGuard(location) && location.state;
  const modalProps = getPublishStatusModalProps();
  const previewUrl = `${location?.origin}/s/${organizationId}/proposal/${proposal.id}`;

  useEffect(() => {
    if (state && Number(state.scrollY) > 0) {
      window.scrollTo(0, Number(state.scrollY));
    }
  }, []);

  return (
    <FormProvider key={proposal.id} {...form}>
      <form
        className="proposal-overview-item"
        onSubmit={form.handleSubmit(submit)}
        key={proposal.id}
        data-cy={`proposal-overview-list-item-${index}`}
      >
        <div className="f u-width--full f--justify-between">
          <div className="proposal-overview-item__main">
            <ProposalOverviewListItemTitle client={proposal.client} />
            {proposal.publishStatus === 'archived' ? (
              <div className="f f--align-center proposal-overview-title__container">
                {isExpiredNotificationVisible && <ProposalExpiredTooltip />}
                <a
                  href={previewUrl}
                  target="_blank"
                  className="proposal-overview-item__title text--xs__responsive t-bold t-ellipsis-2-row"
                  data-cy="proposal-item-title"
                >
                  {proposal.name}
                </a>
              </div>
            ) : (
              <div className="f f--align-center proposal-overview-title__container">
                {isExpiredNotificationVisible && <ProposalExpiredTooltip />}
                <Link
                  to={formatMessage(
                    { id: 'routes.new-proposal' },
                    { id: proposal.id },
                  )}
                  state={{
                    lastPageUrl: `${pathname}${search}`,
                    scrollY: String(scrollPosition),
                  }}
                  className="proposal-overview-item__title text--xs__responsive t-bold t-ellipsis-2-row"
                  data-cy="proposal-item-title"
                >
                  {proposal.name}
                </Link>
              </div>
            )}
          </div>

          <div className="proposal-overview-item__acceptance-status__actions">
            {proposal?.acceptanceStatus &&
              proposal?.publishStatus === 'published' && (
                <div
                  data-cy={`acceptance-status-${index}`}
                  className="d--desktop--only"
                >
                  <AcceptanceStatusSelect proposal={proposal} />
                </div>
              )}
            <div className="proposal-item-create__actions">
              <ActionDrawer
                className="actionbar__drawer"
                isPublishModalOpen={publishStatusModal.isModalOpen}
                btnSelector={`more-option-btn-${index}`}
              >
                <ActionDrawerButtons
                  isOverview
                  proposal={proposal}
                  publishStatus={proposal.publishStatus}
                  archiveClassName="actionbar__drawer__btn"
                  deleteClassName="actionbar__drawer__btn"
                  duplicateAsTemplateClassName="actionbar__drawer__btn"
                  duplicateClassName="actionbar__drawer__btn"
                  unpublishClassName="actionbar__drawer__btn"
                />
              </ActionDrawer>
            </div>
          </div>
        </div>

        <div className="proposal-overview__tags__wrapper">
          <ProposalOverviewListItemStatus
            publishStatus={proposal.publishStatus}
            index={index}
          />
          <span className="t-small t-text-2 footer__terms-service__divider m-bottom-auto">
            ·
          </span>
          <p className="t-tiny t-text-2 proposal-item__creation__date">
            {format(proposal.creationDateTime, 'MMM dd, yyyy')}
          </p>{' '}
          {proposal?.acceptanceStatus &&
            proposal?.publishStatus === 'published' && (
              <div className="proposal-overview__acceptance__status--mobile">
                <span className="t-small t-text-2 footer__terms-service__divider m-bottom-auto">
                  ·
                </span>
                <AcceptanceStatusSelect proposal={proposal} />
              </div>
            )}
        </div>

        {publishStatusModal.isModalOpen && modalProps && (
          <ConfirmationModal
            onClose={onCancel}
            onSubmit={onUpdate}
            title={modalProps['title']}
            subtitle={modalProps['subtitle']}
            confirmationButtonLabel={modalProps['confirmationButtonLabel']}
            declineButtonLabel={modalProps['declineButtonLabel']}
            type={modalProps['type']}
          />
        )}
        <SubscriptionTierWrapper tier="plus">
          {acceptanceStatusModal.isModalOpen && (
            <ConfirmationModal
              onClose={acceptanceStatusModal.closeModal}
              onSubmit={onUpdate}
              title={formatMessage({
                id: 'proposal-editor.acceptance-status-modal.title',
              })}
              subtitle={formatMessage({
                id: 'proposal-editor.acceptance-status-modal.description',
              })}
              confirmationButtonLabel={formatMessage({
                id: 'proposal-editor.acceptance-status-modal.confirm',
              })}
              declineButtonLabel={formatMessage({
                id: 'proposal-editor.acceptance-status-modal.cancel',
              })}
            />
          )}
        </SubscriptionTierWrapper>
      </form>
    </FormProvider>
  );
};
