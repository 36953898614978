import iconProtectedDraft from 'assets/icons/icon-protected-draft-20.svg';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {}

export const ProtectedDraftController: React.FC<Props> = ({}) => {
  return (
    <div
      className="protected-draft-controller"
      data-cy="protected-draft-controller"
    >
      <img
        src={iconProtectedDraft}
        alt="Proposal expired icon"
        className="protected-draft-controller__icon"
      />
      <div
        className="proposal-expired-controller__heading"
        data-cy="protected-draft-title"
      >
        <p className="text--xs__responsive t-text-1">
          <FormattedMessage id="protected-draft.title" />
        </p>

        <p className="text--xxs__responsive t-text-2 proposal-expired-controller__description">
          <FormattedMessage id="protected-draft.description" />
        </p>
      </div>
    </div>
  );
};
