import {
  DocumentData,
  DocumentSnapshot
} from 'firebase/firestore';

export const showDebugStatuses = process.env.NODE_ENV !== 'production';

export function getTypedDocument<T>(
  snapshot: DocumentSnapshot<DocumentData>,
): T | undefined {
  if (!snapshot.exists()) return undefined;
  const doc = { id: snapshot.id, ...snapshot.data() } as unknown as T;
  return doc;
}
