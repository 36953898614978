import sparksIcon from 'assets/icons/icon-sparks-duo-12.svg';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomTagTooltip } from './custom-tags';

export const CustomTagFreemiumSelect: React.FC = ({}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="custom-tags__input__container--disabled">
      <div className="f f--align-center s-bottom--tny">
        <img
          src={sparksIcon}
          alt="Sparks icon"
          className="btn__icon s-right--sml"
        />
        <p className="t-text-2 t-label s-right--sml">
          <FormattedMessage id="inputs.custom-tags.label" />
        </p>
        <CustomTagTooltip />
      </div>
      <div
        className="custom-tags__input__container"
        data-cy="custom-tags-search-bar"
      >
        <input
          type="text"
          className="input custom-tags-search__input"
          placeholder={formatMessage({
            id: 'inputs.custom-tags.placeholder',
          })}
          disabled
        />
      </div>
    </div>
  );
};
