import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'shared/components';
import {
  useAnimatedDropdown,
  useClickOutsideElement,
  useMobile,
} from 'shared/hooks';
import { SortModal } from './SortModal';
import { SortOptions } from './SortOptions';

interface Props {
  sortOption: SortOption;
  btnSelector?: string;
  onSetSortOption: (sortOption: SortOption) => void;
}

export const Sort: React.FC<Props> = ({
  sortOption,
  btnSelector,
  onSetSortOption,
}) => {
  const isMobile = useMobile();
  const { isOpen, handleCloseDropdownToggle, handleOpenDropdownToggle } =
    useAnimatedDropdown();
  const drawerRef = useClickOutsideElement<HTMLDivElement>(
    handleCloseDropdownToggle,
    true,
    true,
  );
  const dropdownRef = isOpen && !isMobile ? drawerRef : null;

  return (
    <div ref={dropdownRef} data-cy={btnSelector}>
      <Button
        type="button"
        className="proposal__sort__btn"
        btnSelector="proposal-sort-btn"
        onClick={isOpen ? handleCloseDropdownToggle : handleOpenDropdownToggle}
      >
        {sortOption ? (
          <span className="t-text-1">
            <FormattedMessage id={`proposals.overview.sort.${sortOption}`} />
          </span>
        ) : (
          <span className="btn__icon__right">
            <FormattedMessage id="buttons.sort" />
          </span>
        )}
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          className="btn__icon f--shrink-zero btn__icon__right proposal__sort__up--down__icon"
          animate={{ rotate: isOpen ? -180 : 0 }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
          fill="none"
        >
          <path
            d="M9.20121 4.99822C9.29953 4.90003 9.36133 4.75899 9.37299 4.60614C9.38465 4.45328 9.34523 4.30112 9.2634 4.18314C9.18157 4.06516 9.06403 3.99102 8.93663 3.97702C8.80924 3.96303 8.68243 4.01033 8.58411 4.10852L6 6.69374L3.41589 4.10852C3.36721 4.0599 3.31102 4.02326 3.25053 4.0007C3.19004 3.97814 3.12644 3.97009 3.06337 3.97702C3.00029 3.98395 2.93896 4.00572 2.8829 4.04109C2.82683 4.07645 2.77712 4.12472 2.7366 4.18314C2.65477 4.30112 2.61535 4.45328 2.62701 4.60614C2.63867 4.75899 2.70047 4.90003 2.79879 4.99822L5.69145 7.89062C5.77808 7.9772 5.88726 8.02461 6 8.02461C6.11274 8.02461 6.22192 7.9772 6.30855 7.89062L9.20121 4.99822Z"
            fill="#706F6F"
          />
        </motion.svg>
      </Button>
      <AnimatePresence>
        {isOpen && (
          <motion.ul
            className="proposal__sort__container s-top--sml"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2, ease: [0.33, 1, 0.68, 1] }}
          >
            <SortOptions
              sortOption={sortOption}
              onSetSortOption={onSetSortOption}
            />
          </motion.ul>
        )}
      </AnimatePresence>
      {isOpen && isMobile && (
        <SortModal
          sortOption={sortOption}
          onSetSortOption={onSetSortOption}
          onClose={handleCloseDropdownToggle}
        />
      )}
    </div>
  );
};
