import {
  DocumentData,
  DocumentSnapshot,
  FirestoreError,
  QuerySnapshot,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../initFirebase';
import { showDebugStatuses } from '../utils';
import { useStorage } from './useStorage';

export const useFirestore = <T>(organizationId: string) => {
  const { processUploadsAsync } = useStorage();

  function resolveData(snapshot: DocumentSnapshot<DocumentData>) {
    return snapshot.exists()
      ? ({ id: snapshot.id, ...snapshot.data() } as unknown as T)
      : null;
  }

  function resolveQuery(snapshot: QuerySnapshot<DocumentData>) {
    return snapshot.docs.map(
      (doc) => ({ id: doc.id, ...doc.data() } as unknown as T),
    );
  }

  function handleError(error: FirestoreError) {
    if (showDebugStatuses) console.log(error);
    return error;
  }

  function isFirestoreError(
    data: FirestoreError | T | null,
  ): data is FirestoreError {
    return Boolean(data) && Boolean((data as FirestoreError).code);
  }

  function getCollectionReference() {
    return collection(db, organizationId);
  }

  function getDocumentReference(documentName: string) {
    return doc(getCollectionReference(), documentName);
  }

  async function createAsync(documentName: string, data: Omit<T, 'id'>) {
    return setDoc(getDocumentReference(documentName), { ...data }).catch(
      handleError,
    );
  }

  async function createWithImagesAsync(
    data: Omit<T, 'id'>,
    imageProps: string[],
    entityId: string,
  ) {
    const ref = getDocumentReference(entityId);

    const processedData = await processUploadsAsync(
      entityId,
      ref.id,
      data,
      imageProps,
    );

    await setDoc(ref, { ...processedData }).catch(handleError);
    return;
  }

  async function getAllAsync() {
    return getDocs(getCollectionReference())
      .then(resolveQuery)
      .catch(handleError);
  }

  async function getByIdAsync(documentName: string) {
    return getDoc(getDocumentReference(documentName))
      .then(resolveData)
      .catch(handleError);
  }

  async function updateAsync(documentName: string, data: Omit<T, 'id'>) {
    await updateDoc(getDocumentReference(documentName), data).catch(
      handleError,
    );
    return;
  }

  async function updateWithImagesAsync(
    data: Omit<T, 'id'>,
    imageProps: string[],
    entityId: string,
  ) {
    const ref = getDocumentReference(entityId);

    const processedData = await processUploadsAsync(
      entityId,
      ref.id,
      data,
      imageProps,
    );
    await updateDoc(ref, { ...processedData }).catch(handleError);
    return;
  }

  async function deleteAsync(documentName: string) {
    await deleteDoc(getDocumentReference(documentName)).catch(handleError);
    return;
  }

  return {
    getDocumentReference,
    isFirestoreError,
    handleError,
    resolveData,
    resolveQuery,
    createAsync,
    getByIdAsync,
    getAllAsync,
    updateAsync,
    deleteAsync,
    processUploadsAsync,
    createWithImagesAsync,
    updateWithImagesAsync,
  };
};
