import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { authenticationSelector } from 'modules/authentication';
import { authorizationSelector } from 'modules/authorization';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { adminRoutes } from '../const';

export function useAdminRedirects() {
  const { user } = useSelector(authenticationSelector.getState);
  const { adminRole, isLoading } = useSelector(authorizationSelector.getState);
  const { pathname } = useLocation();

  const isOnAdminLocation = adminRoutes.some((state) =>
    pathname.includes(state),
  );

  useEffect(() => {
    if (adminRole !== 'superAdmin' && !isLoading) {
      navigate('/dashboard/proposals/overview');
      return;
    }

    if (pathname === '/admin') {
      navigate('/admin/overview');
      return;
    }

    if (!Boolean(user)) {
      navigate('/login');
      return;
    }

    if (!isOnAdminLocation) navigate('/404');
  }, [user]);
}
