import { ErrorMessage } from '@hookform/error-message';
import { useScrollIntoView } from 'modules/authentication';
import { getCurrencySymbol } from 'modules/proposals';
import { onlyNumbersRegex } from 'modules/validation';
import React from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from 'shared/components';
import { useCloseOnEscape, useSubmitOnEnter } from 'shared/hooks';
import { isZeroBeforeDot } from 'shared/utils';

interface Props extends ModalProps {
  currency?: Proposal['currency'];
  ISOCountry?: string;
  item: ProposalTotalDiscount;
}

export const TotalDiscountModal: React.FC<Props> = ({
  currency,
  ISOCountry,
  item,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const { setValue, getValues } =
    useFormContext<PublishStatusAware<Proposal>>();

  const form = useForm<ProposalTotalDiscount>({
    mode: 'onSubmit',
    defaultValues: {
      discount: item?.discount || 0,
      discountType: item?.discountType || 'absolute',
    },
  });
  const { handleFocus } = useScrollIntoView();
  const discountType = form.getValues('discountType');

  const closeButtonRef = useCloseOnEscape<HTMLButtonElement>();
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();

  function getItemClassName(type: ProposalDiscountType) {
    return discountType === type
      ? 'total-discount-modal__discount-item--active'
      : 'total-discount-modal__discount-item--inactive';
  }

  function handleDiscount(discount: number, type: ProposalDiscountType) {
    form.clearErrors('discount');
    form.setValue('discount', discount);
    form.setValue('discountType', type);
  }

  function saveData(data: ProposalTotalDiscount) {
    setValue(
      'totalDiscount',
      {
        discount: data.discount,
        discountType: data.discountType,
      },
      { shouldDirty: true },
    );
    onClose();
  }

  return (
    <Modal close={onClose} isDashboardModal>
      <FormProvider {...form}>
        <ModalHeader>
          <p className="account-delete-modal__header text--xl__responsive">
            Enter subtotal discount
          </p>
        </ModalHeader>
        <ModalContent>
          <div className="f f--col">
            <div className="total-discount-modal__container">
              <Button
                type="button"
                onClick={() => handleDiscount(0, 'absolute')}
                className={`text--sm total-discount-modal__discount-item ${getItemClassName(
                  'absolute',
                )}`}
              >
                <span>
                  Absolute ({getCurrencySymbol(currency, ISOCountry)})
                </span>
              </Button>
              <Button
                type="button"
                onClick={() => handleDiscount(0, 'percentage')}
                className={`text--sm total-discount-modal__discount-item ${getItemClassName(
                  'percentage',
                )}`}
              >
                <span>Percentage (%)</span>
              </Button>
            </div>

            <Controller
              name="discount"
              control={form.control}
              rules={{
                min: {
                  value: 0,
                  message: formatMessage({
                    id: 'inputs.total-discount.min-value-error',
                  }),
                },
                max: {
                  value: discountType === 'percentage' ? 100 : Infinity,
                  message: formatMessage({
                    id: 'inputs.total-discount.max-value-error',
                  }),
                },
                pattern: {
                  value: onlyNumbersRegex,
                  message: formatMessage({
                    id: 'inputs.total-discount.invalid',
                  }),
                },
                validate: (value) => {
                  if (isZeroBeforeDot(String(value))) {
                    return formatMessage({
                      id: 'inputs.total-discount.starts-with-zero',
                    });
                  }
                  return;
                },
              }}
              defaultValue={getValues(`totalDiscount.discount`)}
              render={({ field }) => (
                <>
                  <input
                    {...field}
                    value={field.value}
                    inputMode="decimal"
                    type="number"
                    min={0}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                    max={discountType === 'percentage' ? 100 : Infinity}
                    autoFocus
                    onFocus={handleFocus}
                    className={`input input__med input__box ${
                      !!form.formState.errors?.discount
                        ? 'input__box__error'
                        : ''
                    }`}
                    placeholder="Enter discount"
                    data-cy="total-discount-input"
                  />
                  {form.formState.errors.discount && (
                    <ErrorMessage
                      name="discount"
                      errors={form.formState.errors}
                      as={<p className="t-danger text--sm s-top--tny" />}
                      data-cy="field-validation-error"
                    />
                  )}
                </>
              )}
            />
          </div>
          <div className="total-discount-modal__notice">
            {' '}
            <p className="t-text-1 text--sm">
              This discount will be applied on top of any existing per-service
              discounts. Individual discounts remain unchanged.
            </p>
          </div>
        </ModalContent>
        <ModalFooter>
          <div className="btn-group btn-group--simple">
            <Button
              size="lrg"
              style="outline"
              onClick={onClose}
              ref={closeButtonRef}
              btnSelector="cancel-btn"
            >
              <FormattedMessage id="account-settings.delete-modal.action.cancel" />
            </Button>
            <Button
              size="lrg"
              style="primary"
              type="submit"
              onClick={form.handleSubmit(saveData)}
              ref={submitButtonRef}
              btnSelector="save-total-discount-btn"
            >
              <FormattedMessage id="buttons.save" />
            </Button>
          </div>
        </ModalFooter>
      </FormProvider>
    </Modal>
  );
};
