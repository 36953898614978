import moreIcon from 'assets/icons/icon-more-16.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { dashboardAction } from 'modules/dashboard';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useClickOutsideElement } from 'shared/hooks';
import { Button } from '../button';

interface Props extends PropsWithChildren {
  className: string;
  btnSelector?: string;
}

export const ListItemActionDrawer: React.FC<Props> = ({
  children,
  className,
  btnSelector,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpenDropdownTogle = () => setIsOpen(!isOpen);
  const drawerRef = useClickOutsideElement<HTMLDivElement>(
    () => setIsOpen(false),
    true,
    true,
  );
  const dropdownRef = isOpen ? drawerRef : null;

  useEffect(() => {
    isOpen
      ? dispatch(dashboardAction.success(isOpen))
      : setTimeout(() => dispatch(dashboardAction.success(isOpen)), 200);
  }, [isOpen]);

  return (
    <div className="settings__actionbar__container" ref={dropdownRef}>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={className}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2, ease: [0.33, 1, 0.68, 1] }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
      <Button
        type="button"
        style="ghost"
        className="btn__sml__action-drawer
        btn__sml__action-drawer--iconOnly s-left--sml"
        onClick={handleOpenDropdownTogle}
        data-cy={btnSelector}
      >
        <img src={moreIcon} alt="MoreIcon" className="btn__icon" />
      </Button>
    </div>
  );
};
