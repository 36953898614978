import notFoundIlustration from 'assets/images/404-ilustration.svg';
import { motion } from 'framer-motion';
import { Link, PageProps } from 'gatsby';
import { authenticationSelector } from 'modules/authentication';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DashboardFooter, PublicLayout, TawkLiveChat } from 'shared/components';

/**
 * @url https://github.com/gatsbyjs/gatsby/issues/5329
 * @description Suggested workaround for 404 page flashing on ssr
 */
const browser = (typeof window !== 'undefined' && window) || null;

const Error404Page: React.FC<PageProps> = () => {
  const { formatMessage } = useIntl();
  const { user } = useSelector(authenticationSelector.getState);

  return (
    browser && (
      <>
        <PublicLayout
          rootUrl={formatMessage({ id: 'routes.home' })}
          footerComponent={DashboardFooter}
          contentClassName="restricted-proposal__content"
        >
          <motion.div
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, type: 'intertia' }}
          >
            <img
              src={notFoundIlustration}
              alt="Not found ilustration"
              className="errorpage__ilustration"
            />
          </motion.div>

          <motion.h3
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2, type: 'intertia' }}
            className="text--lg__responsive errorpage__title"
          >
            <FormattedMessage id="404.title" />
          </motion.h3>
          <motion.p
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4, type: 'intertia' }}
            className="text--xxs__responsive s-top--sml t-text-2 errorpage__description"
          >
            <FormattedMessage id="404.description" />
          </motion.p>

          <motion.div
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6, type: 'intertia' }}
            className="s-top--lrg restricted-proposal__button"
            data-cy="restricted-proposal-btn"
          >
            <Link
              to={formatMessage({
                id: `${user ? 'routes.overview' : 'routes.home'}`,
              })}
              className="btn btn__med btn__primary"
            >
              {Boolean(user) ? (
                <FormattedMessage id="errors.public-proposals.no-proposal.user.action" />
              ) : (
                <FormattedMessage id="errors.public-proposals.no-proposal.guest.action" />
              )}
            </Link>
          </motion.div>
          <TawkLiveChat />
        </PublicLayout>
      </>
    )
  );
};

export default Error404Page;
