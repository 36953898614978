import { navigate } from 'gatsby';
import { authenticationSelector } from 'modules/authentication';
import { isOnboardingLastStepAtom, settingsSelector } from 'modules/settings';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';

export function useOnboardingRedirects() {
  const { user } = useSelector(authenticationSelector.getState);
  const { config } = useSelector(settingsSelector.getState);
  const isOnboardingLastStep = useRecoilValue(isOnboardingLastStepAtom);

  useEffect(() => {
    if (!Boolean(user)) navigate('/login');
    const isConfigCreated =
      config && !config.isOnboardingInProgress && !isOnboardingLastStep;

    if (isConfigCreated) {
      navigate('/dashboard/proposals/overview');
    }
  }, [config, isOnboardingLastStep]);

  return Boolean(
    config === undefined ||
      (config && !config.isOnboardingInProgress && !isOnboardingLastStep),
  );
}
