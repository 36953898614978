import {
  Settings,
  SettingsNewsletterCheckbox,
  useSubscribeToNewsletter,
} from 'modules/settings';
import React, { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface Props {
  config: Settings;
  formClassName?: string;
}

export const SettingsNewsletter: React.FC<Props> = ({
  config,
  formClassName,
}) => {
  const form = useForm<Settings>({
    mode: 'onSubmit',
    defaultValues: {
      ...config,
    },
  });

  const { register, formState } = form;
  const { subscribeToNewsletter, unsubscribeFromNewsletter } =
    useSubscribeToNewsletter();

  const isSubscribedToNewsletter = useWatch({
    name: 'isSubscribedToNewsletter',
    control: form.control,
  });

  function handleToggle() {
    form.setValue('isSubscribedToNewsletter', !isSubscribedToNewsletter, {
      shouldDirty: true,
    });
  }

  useEffect(() => {
    return () => {
      if (formState.dirtyFields.isSubscribedToNewsletter) {
        form.getValues('isSubscribedToNewsletter')
          ? subscribeToNewsletter()
          : unsubscribeFromNewsletter();
      }
    };
  }, [formState.dirtyFields]);

  return (
    <FormProvider {...form}>
      <form className={formClassName || ''}>
        <div>
          <span
            className="settings__header__title text--xs t-bold"
            data-cy="account-settings-title"
          >
            <FormattedMessage id="account-settings.section.newsletter.title" />
          </span>
          <p className="t-eta t-text-2 s-top--med">
            <FormattedMessage id="account-settings.section.newsletter.description" />
          </p>
          <li className="newsletter-checkbox__option account-settings__newsletter">
            <SettingsNewsletterCheckbox
              isChecked={isSubscribedToNewsletter || false}
              handleToggle={handleToggle}
              scale={0.833}
              register={register('isSubscribedToNewsletter')}
            />
            <label
              onClick={handleToggle}
              className="text--xs__responsive t-text-2 t-normal account-settings__newsletter__label"
            >
              <FormattedMessage id="account-settings.section.newsletter.label" />
            </label>
          </li>
        </div>
      </form>
    </FormProvider>
  );
};
