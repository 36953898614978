import { motion } from 'framer-motion';
import React from 'react';

interface Props {
  message: JSX.Element;
  description?: JSX.Element;
}

export const ListErrorMessage: React.FC<Props> = ({
  message,
  description,
}) => (
  <div className="f f--col">
    <motion.span
      style={{
        opacity: 0,
        y: 8,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2, type: 'intertia' }}
      className="text--xs__responsive t-text-1 t-center t-bold s-bottom--tny"
      data-cy="overview-proposal-not-found-title"
    >
      {message}
    </motion.span>
    {description && (
      <motion.p
        style={{
          opacity: 0,
          y: 8,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4, type: 'intertia' }}
        data-cy="overview-proposal-not-found-description"
        className="text--xxs__responsive t-text-2 restricted-proposal__description t-center"
      >
        {description}
      </motion.p>
    )}
  </div>
);
