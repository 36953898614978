import { FirestoreError } from 'firebase/firestore';
import { useOrganizationIdSelector } from 'modules/authorization';
import { useFirestoreEntitites } from 'modules/firebase';
import { toast } from 'sonner';

export function useUnits() {
  const organizationId = useOrganizationIdSelector();

  const entityFunctions = useFirestoreEntitites<Unit>('units', organizationId);

  async function createEntityAsync(data: Omit<Unit, 'id'>) {
    try {
      const documentReference = await entityFunctions.createEntityAsync(data);
      if (documentReference instanceof FirestoreError) throw FirestoreError;
      toast.success(`Unit ${data.name} has been successfully added.`);
      return documentReference.id;
    } catch (error) {
      toast.error(
        `We couldn't save the unit with name ${data.name}. Try again later.`,
      );
      return;
    }
  }

  async function updateEntityAsync(entityId: string, data: Omit<Unit, 'id'>) {
    try {
      await entityFunctions.updateEntityAsync(entityId, data);
      toast.success(
        `Unit with name ${data.name} has successfully been updated.`,
      );
    } catch (error) {
      toast.error(
        `We couldn't update the unit with name ${data.name}. Try again later.`,
      );
    }
  }

  async function deleteEntityAsync(entityId: string) {
    try {
      await entityFunctions.deleteEntityAsync(entityId);
      toast.success(`Unit has successfully been deleted.`);
    } catch (error) {
      toast.error(`We couldn't delete the unit. Try again later.`);
    }
  }

  async function reorderEntitiesAsync(data: Omit<Unit[], 'id'>) {
    try {
      await entityFunctions.reorderEntityAsync(data, 'units');
    } catch (error) {
      toast.error(`We couldn't reorder the units. Try again later.`);
    }
  }

  return {
    ...entityFunctions,
    createEntityAsync,
    updateEntityAsync,
    deleteEntityAsync,
    reorderEntitiesAsync,
  };
}
