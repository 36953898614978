import checkmarkIcon from 'assets/icons/icon-checkmark-12.svg';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { MobileOnboardingStepper } from './fragments';

interface Props {
  currentStep: number; // this should now start from 1 but treat as if starting from 2
}

export const OnboardingStepper: React.FC<Props> = ({ currentStep }) => {
  const { formatMessage } = useIntl();
  const steps = [
    { id: 1, label: formatMessage({ id: 'onboarding.stepper.step-1.title' }) },
    { id: 2, label: formatMessage({ id: 'onboarding.stepper.step-2.title' }) },
    { id: 3, label: formatMessage({ id: 'onboarding.stepper.step-3.title' }) },
  ];
  const adjustedStep = useMemo(() => currentStep - 1, [currentStep]);

  return (
    <>
      <div className="onboarding-stepper__container d--desktop--only">
        {steps.map((step, index) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.1 * index }}
            className={`onboarding-stepper__step`}
            key={step.id}
          >
            <div
              className={`onboarding-stepper__circle text--xsm ${
                adjustedStep > step.id
                  ? 'done'
                  : adjustedStep === step.id
                  ? 'is-active'
                  : ''
              }`}
            >
              {adjustedStep > step.id ? (
                <img
                  src={checkmarkIcon}
                  className="btn__icon"
                  alt="Checkmark Icon"
                />
              ) : (
                step.id
              )}
            </div>
            <span
              className={`text--xsm onboarding-stepper__label ${
                adjustedStep > step.id
                  ? 'done'
                  : adjustedStep === step.id
                  ? 'is-active'
                  : ''
              }`}
            >
              {step.label}
            </span>
            {index < steps.length - 1 && (
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`btn__icon onboarding-stepper__arrow ${
                  adjustedStep > step.id
                    ? 'done'
                    : adjustedStep === step.id
                    ? 'is-active'
                    : ''
                }`}
              >
                <path
                  d="M4.70211 1.01987C4.57481 0.866911 4.39195 0.770783 4.19377 0.752639C3.9956 0.734495 3.79833 0.79582 3.64536 0.923124C3.4924 1.05043 3.39627 1.23328 3.37813 1.43146C3.35998 1.62964 3.42131 1.82691 3.54861 1.97987L6.90036 5.99987L3.54861 10.0199C3.48558 10.0956 3.43808 10.183 3.40883 10.2771C3.37957 10.3712 3.36914 10.4702 3.37813 10.5683C3.38711 10.6664 3.41534 10.7618 3.46119 10.849C3.50704 10.9363 3.56962 11.0136 3.64536 11.0766C3.79833 11.2039 3.9956 11.2653 4.19377 11.2471C4.39195 11.229 4.57481 11.1328 4.70211 10.9799L8.45211 6.47987C8.56436 6.34511 8.62583 6.17526 8.62583 5.99987C8.62583 5.82448 8.56436 5.65464 8.45211 5.51987L4.70211 1.01987Z"
                  fill="#3B3B3B"
                />
              </svg>
            )}
          </motion.div>
        ))}
      </div>
      <MobileOnboardingStepper currentStep={adjustedStep} />
    </>
  );
};
