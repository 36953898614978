import { createAction } from 'modules/redux-store';

export const adminAction = {
  loading: () => createAction('admin/LOADING'),

  addUsersData: (users: UserRecord[]) =>
    createAction('admin/ADD_USERS_DATA', { users }),

  addProposalStatsData: (stats: ProposalCounter<number>) =>
    createAction('admin/ADD_PROPOSAL_STATS_DATA', { stats }),

  addNumberOfActiveUsersLastMonth: (numberOfActiveUsersLastMonth: number) =>
    createAction('admin/ADD_NUMBER_OF_ACTIVE_USERS_LAST_MONTH', {
      numberOfActiveUsersLastMonth,
    }),

  addUsersToken: (pageToken: string) =>
    createAction('admin/ADD_USERS_TOKEN', { pageToken }),

  addUserReferralsData: (referrals: ReferralAnalytics[]) =>
    createAction('admin/ADD_USER_REFERRALS_DATA', { referrals }),

  clearUserToken: () => createAction('admin/CLEAR_USER_TOKEN'),

  error: (error?: FirebaseAuthError) =>
    createAction('admin/ON_SNAPSHOT_ERROR', { error }),

  resetErrors: () => createAction('admin/RESET_ERRORS'),

  logout: () => createAction('admin/LOGOUT'),
};
