import { navigate } from 'gatsby';
import { authenticationSelector } from 'modules/authentication';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { SELECTED_PRODUCT_LS_KEY } from 'shared/const';
import { useLocalStorage } from 'shared/hooks';
import { toast } from 'sonner';
import { SelectedProduct } from '../models';
import { subscriptionAtoms } from '../state';
import { useStripeCheckout } from './checkout';
import { useSubscriptionHelpers } from './useSubscriptionHelpers';

export function usePricingSelectPlan() {
  const [, setSelectedProduct] = useLocalStorage<SelectedProduct>(
    SELECTED_PRODUCT_LS_KEY,
    {
      clearStorageOnLoad: true,
    },
  );

  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);

  const { user } = useSelector(authenticationSelector.getState);
  const customerId = useRecoilValue(subscriptionAtoms.customerId);

  const { createBillingPortal } = useStripeCheckout();
  const { isFreePlan } = useSubscriptionHelpers();

  async function handleCheckout() {
    try {
      if (!customerId) {
        toast.error(
          'Please wait for a few seconds while your auto-free subscription is activating. Thank you for your patience!',
        );
        return;
      }

      setIsCheckoutLoading(true);

      const billingPortalData = await createBillingPortal(customerId);

      if (!billingPortalData || !billingPortalData.url) {
        toast.error(
          'Sorry, the creation of your checkout failed. Please try again later or contact support@propoze.app for assistance.',
        );
        return;
      }

      navigate(billingPortalData.url);
    } catch (e) {
      throw new Error('Could not create checkout session');
    } finally {
      setIsCheckoutLoading(false);
    }
  }

  async function handleSelect(
    priceId: string,
    product: StripeProductWithPrices,
  ) {
    const isFree = isFreePlan({ priceId, productId: product.id });

    if (!Boolean(user)) {
      !isFree && setSelectedProduct({ priceId, productId: product.id });
      navigate('/sign-up/');
      return;
    }

    await handleCheckout();
  }

  return {
    isCheckoutLoading,
    handleSelect,
    handleCheckout,
  };
}
