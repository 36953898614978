import {
  getExchangeRate,
  getNewCurrency,
  isDualPricingCurrency,
} from 'modules/proposals';
import { useIntl } from 'react-intl';
import { useISOCountry } from 'shared/hooks';
import { getCurrencyFromISOCountry } from 'shared/utils';
import { getPriceCurrencyAndAmountString } from '../utils';

export function useStripeHelpers() {
  const { formatMessage } = useIntl();
  const { ISOCountry } = useISOCountry();

  function getPriceFromInterval(
    prices: StripePrice[],
    interval: PaymentInterval,
  ) {
    if (!interval) return;
    return prices.find(({ recurring }) => recurring?.interval === interval);
  }

  function getProductPriceStringByInterval(
    price: StripePrice | undefined,
    interval: PaymentInterval | undefined,
  ): {
    price: string | null;
    interval: PaymentInterval | undefined;
    dualPrice?: string | null;
  } {
    const oldCurrency = getCurrencyFromISOCountry(ISOCountry);
    const isDualPricingEnabled = isDualPricingCurrency(oldCurrency);

    if (!price?.unit_amount || !price.currency_options) {
      return {
        interval: undefined,
        price: formatMessage({
          id: 'subscription-product-item.price.free',
        }),
      };
    }

    if (isDualPricingEnabled) {
      const newCurrency = getNewCurrency(oldCurrency);
      const exchangeRate = getExchangeRate(oldCurrency);
      const amount = Number(
        price.currency_options[newCurrency.toLowerCase()]?.unit_amount,
      );

      return {
        interval,
        price: getPriceCurrencyAndAmountString(amount, newCurrency, ISOCountry),
        dualPrice: getPriceCurrencyAndAmountString(
          amount * exchangeRate,
          oldCurrency,
          ISOCountry,
        ),
      };
    }

    return {
      interval,
      price: getPriceCurrencyAndAmountString(
        Number(price.currency_options[oldCurrency.toLowerCase()]?.unit_amount),
        oldCurrency,
        ISOCountry,
      ),
    };
  }

  function getProductFeatureListFromMetadata(metadata: StripeMetadata) {
    return Object.keys(metadata)
      .filter((key) => key.includes('feature'))
      .sort(
        (a, b) => parseInt(a.split('_')[1], 10) - parseInt(b.split('_')[1], 10),
      )
      .map((key) => metadata[key]);
  }

  function getPriceWithDiscount(
    price: string | null,
    discount?: number | null,
  ) {
    if (!price || !discount) return;
    const totalPrice =
      parseFloat(price.replace(',', '.')) * (1 - discount / 100);

    return totalPrice.toFixed(2).replace('.', ',');
  }

  return {
    getProductPriceStringByInterval,
    getPriceFromInterval,
    getProductFeatureListFromMetadata,
    getPriceWithDiscount,
  };
}
