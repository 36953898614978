import clipIcon from 'assets/icons/icon-clip-48-plus.svg';
import { SubscriptionSelectModal } from 'modules/subscription';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'shared/components';
import { useSubmitOnEnter } from 'shared/hooks';


export const MediaFilePlusModal: React.FC<ModalProps> = ({ onClose }) => {
  const submitButtonRef = useSubmitOnEnter<HTMLButtonElement>();
  const [currentStep, setCurrentStep] = useState(1);

  const plusModal = {
    [1]: (
      <Modal close={onClose} containerClassName="proposal-service__plus__modal">
        <div className="f f--col f--align-center">
          <img src={clipIcon} alt="ClipIcon" />
          <h1 className="t-center text--xl__responsive t-text-1 t-bold proposal-service__plus__modal__title">
            <FormattedMessage id="file-media-plus-modal.title" />
          </h1>
          <div className="d--ib text--xs t-center u-maxwidth--432 s-bottom--med">
            <Button
              type="button"
              onClick={() => setCurrentStep(currentStep + 1)}
              className="btn__slim t-primary t-bold"
              btnSelector="propoze-plus-notice-upgrade-btn"
            >
              <FormattedMessage id="file-media-plus-modal.description.action" />
            </Button>
            <span className="t-text-2">
              <FormattedMessage id="file-media-plus-modal.description" />
            </span>
          </div>
        </div>
        <div className="btn-group btn-group--simple f--center">
          <Button
            size="lrg"
            style="primary"
            ref={submitButtonRef}
            onClick={() => setCurrentStep(currentStep + 1)}
            className="s-top--sml"
            btnSelector="proposal-expired-failed-confirm-btn"
          >
            <FormattedMessage id="buttons.upgrade-now" />
          </Button>
        </div>
      </Modal>
    ),
    [2]: <SubscriptionSelectModal onClose={onClose} />,
  };

  return <>{plusModal[currentStep]}</>;
};
