import clockIcon from 'assets/icons/icon-clock-12-primary.svg';
import { format } from 'date-fns';
import { navigate } from 'gatsby';
import { FeatureFlagWrapper } from 'modules/feature-flags';
import { firebaseAtoms } from 'modules/firebase';
import {
  ProposalAcceptanceController,
  ProposalAcceptanceStatus,
  ProposalExpiredController,
  ProtectedDraftController,
  generateColor,
  generateTypography,
  useProposalExpired,
} from 'modules/proposals';
import { PropozePlusWrapper } from 'modules/subscription';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { Button } from 'shared/components';
import {
  PublicClientInfo,
  PublicCompanyInfo,
  PublicProposalPrices,
  PublicSegmentRenderer,
} from './fragments';

interface Props {
  proposal: Proposal;
  organizationId: string;
  logoUrl?: string | null;
  /** Whether the current user can edit the proposal */
  hasPermissions: boolean;
  /** Whether the current user is already authorized.
   * @example authorized = false will require authorization when modifying the proposal. */
  authorized: boolean;
  validUntilDateTime?: number;
  isInBuilder?: boolean;
  toggleView?: (newView: ProposalSummaryView) => void;
}

export const PublicProposalInfo: React.FC<Props> = ({
  proposal,
  organizationId,
  logoUrl,
  hasPermissions,
  authorized,
  isInBuilder,
  toggleView,
}) => {
  const { formatMessage } = useIntl();

  const blobUrlCache = useRecoilValue(firebaseAtoms.blobUrlCache);
  const { isProposalExpiredSectionEnabled, isProposalExpired } =
    useProposalExpired(hasPermissions, proposal);
  const isFirstItemHeading = useMemo(
    () =>
      proposal?.segments.length > 0 &&
      (proposal?.segments[0].type === 'heading' ||
        proposal?.segments[0].type === 'text'),
    [proposal.segments],
  );
  const isProposalAcceptanceSectionEnabled = useMemo(
    () =>
      proposal.publishStatus === 'published' &&
      !isProposalExpiredSectionEnabled,
    [proposal.publishStatus, isProposalExpiredSectionEnabled],
  );
  const isClientPreviewingDraft = useMemo(
    () => proposal?.publishStatus === 'draft' && !hasPermissions,
    [proposal?.publishStatus, hasPermissions],
  );
  const hasCoverImage = useMemo(() => !!proposal.imageUrl, [proposal.imageUrl]);
  const hasResponse = useMemo(
    () => proposal?.acceptanceStatus !== 'pending',
    [proposal?.acceptanceStatus],
  );

  function handleExtendValidity() {
    if (!isInBuilder) {
      navigate(
        formatMessage({ id: 'routes.new-proposal' }, { id: proposal.id }),
      );
      return;
    }

    toggleView?.('table-of-contents');
  }

  let headerClassName = 'proposal__header';
  if (proposal.imageUrl) headerClassName += ' proposal__header__img';
  if (!proposal.imageUrl) headerClassName += ' proposal__header__no--img';

  return (
    <>
      <header
        className={headerClassName}
        style={
          proposal?.imageUrl
            ? {
                backgroundImage: `url(${
                  blobUrlCache[`imageUrl?proposalId=${proposal.id}`] ||
                  proposal.imageUrl
                })`,
              }
            : {
                backgroundColor: generateColor('background', proposal),
              }
        }
      />
      <div className="d--desktop--only">
        <div
          className={`proposal__header__heading wrapper ${
            proposal?.servicePricesVisibility === 'hidden'
              ? 'wrapper--extra-tight'
              : 'wrapper--tight'
          } ${
            !hasPermissions && !hasCoverImage && !hasResponse
              ? 's-top--96'
              : 's-top--48'
          }`}
        >
          <h1
            style={{
              color: generateColor('proposalTitle', proposal),
              fontFamily: generateTypography('heading', proposal).fontFamily,
              fontWeight: generateTypography('heading', proposal).fontWeight,
            }}
            className="proposal__header__name text--xxxl__responsive t-shorten t-negative t-normal"
          >
            {proposal.name}
          </h1>
        </div>
      </div>

      <div
        style={{
          fontFamily: generateTypography('body', proposal).fontFamily,
          fontWeight: generateTypography('body', proposal).fontWeight,
        }}
      >
        <div
          className={`public-proposal__container ${
            proposal?.servicePricesVisibility === 'hidden'
              ? 'wrapper--extra-tight'
              : 'wrapper--tight'
          }`}
        >
          <div
            className={`public-proposal__content ${isProposalAcceptanceSectionEnabled && !hasCoverImage && !hasResponse && 'public-proposal__content--acceptance'}`}
          >
            {isClientPreviewingDraft && (
              <div className="public-proposal__expired__proposal--top">
                <ProtectedDraftController />
              </div>
            )}
            <p
              style={{
                color: generateColor('proposalTitle', proposal),
                fontFamily: generateTypography('heading', proposal).fontFamily,
                fontWeight: generateTypography('heading', proposal).fontWeight,
              }}
              className="proposal__header__name--mobile text--xxl__responsive t-shorten"
            >
              {proposal.name}
            </p>
            <section
              style={{ color: generateColor('bodyText', proposal) }}
              className="proposal__general"
            >
              <PublicClientInfo client={proposal.client} />
              <div
                className={`proposal__meta ${isProposalExpired && proposal?.servicePricesVisibility === 'hidden' ? 'proposal__meta--expired' : ''}`}
              >
                <p className="text--xs__responsive">
                  <FormattedMessage id="proposals.created" />:{' '}
                  {format(
                    proposal.displayDateTime || proposal.creationDateTime,
                    'MMMM dd, yyyy, HH:mm',
                  )}
                </p>
                <div className="f f--col">
                  <p
                    className={`text--xs__responsive s-top--sml ${
                      isProposalExpired ? 'proposal__expired__container' : ''
                    }`}
                    data-cy="expired-date-info"
                  >
                    <FormattedMessage
                      id={
                        isProposalExpired
                          ? 'datepickers.validity-date.expired-label'
                          : 'datepickers.validity-date.label'
                      }
                    />
                    {`${isProposalExpired ? ' ' : ': '}`}
                    <span
                      className={isProposalExpired ? 't-bold t-text-2' : ''}
                    >
                      {format(
                        proposal.validUntilDateTime,
                        'MMMM dd, yyyy, HH:mm',
                      )}
                    </span>
                  </p>
                  {isProposalExpired && hasPermissions && (
                    <Button
                      type="button"
                      onClick={handleExtendValidity}
                      className="f proposal__expired__extend__link c-pointer s-top--sml"
                      btnSelector="extend-validity-btn"
                    >
                      <img
                        src={clockIcon}
                        className="btn__icon btn__icon__left c-pointer"
                        alt="ClockIcon"
                      />
                      <span className="text--xsm">
                        <FormattedMessage id="proposals.extend-validity" />
                      </span>
                    </Button>
                  )}
                  {isProposalExpiredSectionEnabled && (
                    <ProposalExpiredController
                      proposalId={proposal.id || ''}
                      ownerNotifiedDate={
                        new Date(proposal.ownerNotifiedDate || 0)
                      }
                    />
                  )}
                </div>
              </div>
            </section>
            {isFirstItemHeading && (
              <div
                style={{
                  borderBottom: `1px solid ${generateColor(
                    'borders',
                    proposal,
                  )}`,
                }}
                className="wrapper proposal__details__divider"
              />
            )}
          </div>
        </div>
      </div>
      <section className="proposal__details">
        <div
          className={`wrapper ${
            proposal?.servicePricesVisibility === 'hidden'
              ? 'wrapper--extra-tight'
              : 'wrapper--tight'
          } proposal__details__wrapper`}
        >
          {proposal.segments.map((item, index) => (
            <PublicSegmentRenderer
              key={index}
              item={item}
              index={index}
              style={proposal?.style}
              currency={proposal.currency}
              servicePricesVisibility={proposal.servicePricesVisibility}
            />
          ))}
        </div>
        {proposal.currency && (
          <PublicProposalPrices
            vatPercentage={proposal.vatPercentage}
            segments={proposal.segments}
            currency={proposal.currency}
            discountType={proposal?.totalDiscount?.discountType}
            totalDiscountAmount={proposal?.totalDiscount?.discount || 0}
            proposal={proposal}
            style={proposal?.style}
          />
        )}
        <PropozePlusWrapper organizationId={organizationId}>
          {isClientPreviewingDraft && (
            <div
              className={`wrapper ${
                proposal?.servicePricesVisibility === 'hidden'
                  ? 'wrapper--extra-tight'
                  : 'wrapper--tight'
              } public-proposal__expired__proposal--bottom proposal__details__wrapper`}
            >
              <ProtectedDraftController />
            </div>
          )}
          {isProposalAcceptanceSectionEnabled && (
            <FeatureFlagWrapper flag="ACCEPTANCE">
              <div
                className={`${
                  !hasPermissions && !hasResponse
                    ? 'proposal-acceptance-controller__wrapper'
                    : 'wrapper proposal__details__wrapper s-top--20'
                } ${
                  proposal?.servicePricesVisibility === 'hidden'
                    ? 'wrapper--extra-tight'
                    : 'wrapper--tight'
                }`}
              >
                {hasPermissions ? (
                  <ProposalAcceptanceStatus
                    status={proposal.acceptanceStatus}
                    acceptanceDateTime={proposal.acceptanceDateTime}
                  />
                ) : (
                  <ProposalAcceptanceController
                    proposalId={proposal.id || ''}
                    organizationId={organizationId}
                    status={proposal.acceptanceStatus}
                    responseStatus={proposal.responseStatus}
                    acceptanceDateTime={proposal.acceptanceDateTime}
                    authorized={authorized}
                    buttonColor={proposal.style?.color?.accentColor}
                  />
                )}
              </div>
            </FeatureFlagWrapper>
          )}
        </PropozePlusWrapper>
        <div
          className={`wrapper ${
            proposal?.servicePricesVisibility === 'hidden'
              ? 'wrapper--extra-tight'
              : 'wrapper--tight'
          } proposal__details__wrapper`}
        >
          <footer
            className="proposal__footer"
            style={{
              borderTop: `1px solid ${generateColor('borders', proposal)}`,
              fontFamily: generateTypography('body', proposal).fontFamily,
              fontWeight: generateTypography('body', proposal).fontWeight,
            }}
          >
            <div className="proposal__footer__companyinfo">
              <div className="proposal__footer__companyinfo__branding">
                {logoUrl && <img alt="company logo" src={logoUrl} />}
              </div>
              <PublicCompanyInfo
                settings={proposal.companyInfo}
                proposal={proposal}
              />
            </div>
          </footer>
        </div>
      </section>
    </>
  );
};
