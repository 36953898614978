import { countryToLocaleMap } from 'shared/const';

/**
 * Retrieves the currency symbol for a given currency code.
 *
 * @param {string} currencyCode - The ISO 4217 currency code (e.g., 'USD', 'EUR', 'JPY').
 * @returns {string} The currency symbol or the original currency code if a symbol is not found.
 *
 * @example
 * // Returns '$'
 * getCurrencySymbol('USD');
 *
 */

export function getCurrencySymbol(
  currencyCode?: string | null,
  ISOCountry = 'US',
): string {
  const locale = countryToLocaleMap[ISOCountry] || 'en-US';
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode || 'EUR',
    useGrouping: true,
    currencyDisplay: 'narrowSymbol',
    localeMatcher: 'best fit',
  });

  const parts = formatter?.formatToParts(0);
  const symbol =
    parts?.find((part) => part.type === 'currency')?.value || currencyCode;

  return symbol || '€';
}
