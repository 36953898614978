import iconArrowDown from 'assets/icons/icon-arrow-down-12.svg';
import closeIcon from 'assets/icons/icon-close-12-filter.svg';
import { AnimatePresence, motion } from 'framer-motion';
import {
  filterPlurals,
  useProposalOverviewFilterList,
  useProposalOverviewGroupedFilters,
} from 'modules/proposals-overview';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAnimatedDropdown, useClickOutsideElement } from 'shared/hooks';
import { replaceHyphensWithSpaces } from 'shared/utils';
import { ProposalOverviewFilterOptions } from '../proposal-overview-filter-options';

interface Props {
  filterOption: FilterOption;
  handleRemoveFilter: (index: number) => void;
}

export const ProposalOverviewFilter: React.FC<Props> = ({
  filterOption,
  handleRemoveFilter,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const { filters, removeFilterGroup, renderFilterValue } =
    useProposalOverviewFilterList();
  const groupedFilters = useProposalOverviewGroupedFilters(filters);

  const { isOpen, handleCloseDropdownToggle, handleOpenDropdownToggle } =
    useAnimatedDropdown();
  const drawerRef = useClickOutsideElement<HTMLDivElement>(
    handleCloseDropdownToggle,
    true,
    true,
  );
  const dropdownRef = isOpen && !isCalendarOpen ? drawerRef : null;

  function handleRemoveFilterGroup() {
    groupedFilters[filterOption]?.count > 1
      ? removeFilterGroup(filterOption)
      : handleRemoveFilter(
          filters.indexOf(groupedFilters[filterOption]?.currFilters[0]),
        );
  }

  return (
    <div
      ref={dropdownRef}
      className={`proposal-overview-filter__select__container ${
        isOpen ? 'is-active' : ''
      } ${groupedFilters[filterOption]?.count ? 'is-selected' : ''}`}
      data-cy="proposal-overview-filter-container"
    >
      {!groupedFilters[filterOption]?.count ? (
        <div className="f u-height--full" onClick={handleOpenDropdownToggle}>
          <span className="proposal-overview-filter__select__filter__label t-label t-capital">
            {replaceHyphensWithSpaces(filterOption)}
          </span>
          <div className="proposal-overview-filter__select__arrow__container">
            <img src={iconArrowDown} alt="Arrow down icon" />
          </div>
        </div>
      ) : (
        <div className="u-pos--rel u-height--full">
          <div className="proposal-overview__filter__group">
            {' '}
            <div
              className={`proposal-overview-filter__select__filter__value t-label t-ellipsis ${
                groupedFilters[filterOption]?.count > 1 && 't-lower'
              }`}
              onClick={handleOpenDropdownToggle}
            >
              {groupedFilters[filterOption]?.count > 1
                ? `${groupedFilters[filterOption]?.count} ${
                    filterPlurals[filterOption] || filterOption
                  }`
                : renderFilterValue(
                    groupedFilters[filterOption]?.currFilters[0],
                  )}

              <div className="proposal-overview-filter__group__tooltip">
                <span className="text--xxs__responsive">
                  <FormattedMessage id="proposals.overview.filter.edit" />
                </span>
                <div className="proposal-overview-filter__group__tooltip__arrow" />
              </div>
            </div>
            <div
              className="proposal-overview-filter__select__close__container"
              onClick={handleRemoveFilterGroup}
            >
              <img src={closeIcon} alt="CloseIcon" />
            </div>
          </div>
        </div>
      )}

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="proposal-overview__filter__container"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2, ease: [0.33, 1, 0.68, 1] }}
          >
            <ProposalOverviewFilterOptions
              filterOption={filterOption}
              onSetIsCalendarOpen={setIsCalendarOpen}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
