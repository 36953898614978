import { countries } from 'countries-list';
import { useScrollIntoView } from 'modules/authentication';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { FieldWrapper, OptionType, getSelectStyles } from 'shared/components';
import { isOptionType } from 'shared/utils';

interface SelectedOption {
  value: string;
  label: string;
}

interface Props {
  label: string;
  placeholder: string;
  required?: string;
}

export const CountrySelect: React.FC<Props> = ({
  label,
  placeholder,
  required,
}) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();
  const [menuOpen, setMenuOpen] = useState(false);

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<Settings>();

  const options: OptionType[] = useMemo(
    () =>
      Object.entries(countries)
        .map(([, { name }]) => getValue(name))
        .filter(isOptionType)
        .sort((a, b) => a.label.localeCompare(b.label)),
    [],
  );

  function getValue(countryName?: string | null) {
    if (!countryName) return null;

    return {
      value: countryName,
      label: countryName,
    };
  }

  function handleOpen() {
    setMenuOpen(true);
  }

  function handleClose() {
    setMenuOpen(false);
  }

  useEffect(() => {
    const selectedEl = document.getElementsByClassName('country-dropdown')[0];
    if (!menuOpen || !selectedEl) return;

    const timeoutId = setTimeout(() => {
      selectedEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }, 15);

    return () => clearTimeout(timeoutId);
  }, [menuOpen]);

  return (
    <div data-cy="country-dropdown">
      <FieldWrapper
        name="address.country"
        labelId={label}
        isRequired={!!required}
        errors={errors}
      >
        <Controller
          name="address.country"
          control={control}
          rules={{
            required:
              required &&
              formatMessage({
                id: required,
              }),
          }}
          defaultValue={getValues('address.country') || null}
          render={({ field }) => (
            <Select
              {...field}
              options={options}
              onChange={(selectedOption: SelectedOption | null) =>
                field.onChange(selectedOption?.value || null)
              }
              value={getValue(field.value)}
              onFocus={handleFocus}
              id="currency__styled__select"
              isSearchable
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.stopPropagation();
              }}
              onMenuOpen={handleOpen}
              onMenuClose={handleClose}
              styles={{
                ...getSelectStyles(!!errors.address?.country),
                menuList: (provided) => ({
                  ...provided,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
              }}
              className="country-dropdown"
              placeholder={formatMessage({
                id: placeholder,
              })}
            />
          )}
        />
      </FieldWrapper>
    </div>
  );
};
