import { useParams } from '@reach/router';
import protectedProposalIlustration from 'assets/images/protected-proposal-ilustration.svg';
import { PageProps } from 'gatsby';
import {
  ProtectedProposalAuthorization,
  PublicProposalController,
  PublicProposalNoPermissions,
  PublicProposalNotFound,
  useProposalLocked,
  useProposalPermissions,
  usePublicProposalListener,
} from 'modules/proposals';
import {
  subscriptionSelectors,
  useFetchOrganizationProposalData,
} from 'modules/subscription';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  DashboardFooter,
  PageLoading,
  PreviewLayout,
  SEO,
} from 'shared/components';

export const PublicProposal: React.FC<PageProps> = () => {
  const { organizationId = '' } = useParams() || {};
  const [isUnlocked, setIsUnlocked] = useState(false);

  const { isTierLoading } = useFetchOrganizationProposalData(organizationId);
  const { proposal, isLoading: isProposalLoading } =
    usePublicProposalListener(organizationId);
  const isOrganizationFreeUser = useRecoilValue(
    subscriptionSelectors.getIsFreeUser(organizationId),
  );
  const { hasPermissions } = useProposalPermissions(organizationId);
  const { isProposalLocked } = useProposalLocked(
    organizationId,
    hasPermissions,
    proposal,
  );

  /** Organization tier and proposal loading */
  if (isTierLoading || isProposalLoading) {
    return <PageLoading isLoading={isTierLoading || isProposalLoading} />;
  }

  /**Render protected proposal authorization screen */
  if (isProposalLocked && !isUnlocked && proposal) {
    return (
      <>
        <SEO
          title="Protected proposal"
          description="This proposal is protected."
        />
        <PreviewLayout
          footerComponent={DashboardFooter}
          contentClassName="protected-proposal__content"
          headerClassName="public-proposal__header"
          proposalCompanyLogo={proposal?.companyInfo?.companyImage}
          proposalCompanyName={proposal?.companyInfo?.name}
        >
          <div className="protected-proposal__authorization">
            <ProtectedProposalAuthorization
              onAuthorized={() => setIsUnlocked(true)}
              organizationId={organizationId}
              proposalId={proposal.id || ''}
            />
          </div>
          <div className="protected-proposal__graphic">
            <img
              src={protectedProposalIlustration}
              alt="Protected proposal ilustration"
              className="protected-proposal__graphic__image"
            />
          </div>
        </PreviewLayout>
      </>
    );
  }

  /**Render not found screen */
  if (!proposal) {
    return (
      <>
        <SEO
          title="Not found proposal"
          description="Not found proposal"
        />
        <PreviewLayout
          footerComponent={DashboardFooter}
          contentClassName="restricted-proposal__content"
          headerClassName="public-proposal__header"
        >
          <PublicProposalNotFound />
        </PreviewLayout>
      </>
    );
  }

  /**Validation for free accounts */
  const { publishStatus, visibility } = proposal;
  const isFreePlanValidationFulfilled =
    isOrganizationFreeUser && publishStatus !== 'published';
  /**Validation for paid accounts */
  const isPlusPlanValidationFulfilled =
    (visibility !== 'protected' && publishStatus === 'draft') ||
    publishStatus === 'archived' ||
    publishStatus === 'template';

  /**Render no access screen to users without permission who satisfy free or paid plan validation */
  if (
    (isFreePlanValidationFulfilled || isPlusPlanValidationFulfilled) &&
    !hasPermissions
  ) {
    return (
      <>
        <SEO
          title="Not accessible proposal"
          description={`You don't have access to this proposal`}
        />
        <PreviewLayout
          footerComponent={DashboardFooter}
          contentClassName="restricted-proposal__content"
          headerClassName="public-proposal__header"
          proposalCompanyLogo={proposal?.companyInfo?.companyImage}
          proposalCompanyName={proposal?.companyInfo?.name}
        >
          <PublicProposalNoPermissions />
        </PreviewLayout>
      </>
    );
  }

  return (
    <PublicProposalController
      organizationId={organizationId}
      hasPermissions={hasPermissions}
      isUnlocked={isUnlocked}
      proposal={proposal}
    />
  );
};
