import { onlyNumbersRegex } from 'modules/validation';
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';
import { isZeroBeforeDot } from 'shared/utils';

interface Props {
  index: number;
  control: UseFormReturn<PublishStatusAware<Proposal>>['control'];
  formState: UseFormReturn<PublishStatusAware<Proposal>>['formState'];
  value?: number;
}

export const DiscountInput: React.FC<Props> = ({
  control,
  formState: { errors },
  index,
  value,
}) => {
  const { formatMessage } = useIntl();

  return (
    <FieldWrapper
      name={`segments.${index}.discount`}
      labelId="inputs.discount.label"
      isRequired
      errors={errors}
    >
      <Controller
        name={`segments.${index}.discount`}
        control={control}
        rules={{
          required: formatMessage({ id: 'inputs.discount.required' }),
          max: {
            value: 100,
            message: formatMessage({ id: 'inputs.discount.max' }),
          },
          pattern: {
            value: onlyNumbersRegex,
            message: formatMessage({ id: 'inputs.discount.invalid' }),
          },
          validate: (value) => {
            if (isZeroBeforeDot(String(value))) {
              return formatMessage({
                id: 'inputs.discount.starts-with-zero',
              });
            }
            return;
          },
        }}
        defaultValue={value}
        render={({ field, fieldState }) => (
          <input
            {...field}
            inputMode="decimal"
            type="number"
            className={`input input__med input__box ${
              !!fieldState.error ? 'input__box__error' : ''
            }`}
            placeholder={formatMessage({
              id: 'inputs.discount.placeholder',
            })}
            data-cy={`builder-discount-input-${index}`}
          />
        )}
      />
    </FieldWrapper>
  );
};
