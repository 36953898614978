import React, { useCallback } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from 'react-hook-form';

export const ProposalServicePricesVisibilityToggle: React.FC = () => {
  const { control } = useFormContext<PublishStatusAware<Proposal>>();
  const servicePricesVisibility = useWatch({
    name: 'servicePricesVisibility',
  });

  const handleToggleServicePricesVisibilityToggle = useCallback(
    (
        field: ControllerRenderProps<
          PublishStatusAware<Proposal>,
          'servicePricesVisibility'
        >,
      ) =>
      () => {
        field.onChange(
          servicePricesVisibility === 'hidden' ? 'visible' : 'hidden',
        );
      },
    [servicePricesVisibility],
  );

  return (
    <div className="f f--justify-between f--align-start g-32">
      <div className="f f--col g-4">
        <div className="f g-8 f--align-center">
          <span className="text--xs t-text-1">
            Hide prices for individual services for this proposal
          </span>
        </div>
        <span className="t-text-3 text--sm">
          Toggle to display or hide individual service prices in your proposal.
          When hidden, only the total price will be shown at the bottom.
        </span>
      </div>
      <div className="f f--align-center">
        <label className="toggle__label">
          <Controller
            control={control}
            name="servicePricesVisibility"
            render={({ field }) => (
              <input
                {...field}
                type="checkbox"
                name="servicePricesVisibility"
                className="toggle__input"
                checked={servicePricesVisibility === 'hidden'}
                onChange={handleToggleServicePricesVisibilityToggle(field)}
              />
            )}
          />
          <span
            className="toggle__span"
            data-cy="proposal-service-prices-visibility-toggle"
          />
        </label>
      </div>
    </div>
  );
};
