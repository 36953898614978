import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, SortCheckmarkIcon } from 'shared/components';

const sortOptions: SortOption[] = [
  'newest',
  'oldest',
  'ascending',
  'descending',
];

interface Props {
  sortOption: SortOption;
  onSetSortOption: (sortOption: SortOption) => void;
}

export const SortOptions: React.FC<Props> = ({
  sortOption,
  onSetSortOption,
}) => {
  function isActive(currentSortOption: SortOption) {
    return currentSortOption === sortOption;
  }

  function handleClick(sortStatus: SortOption) {
    onSetSortOption(sortStatus);
  }

  return (
    <>
      {sortOptions.map((option, index) => (
        <React.Fragment key={option}>
          <Button
            type="button"
            className={`btn actionbar__drawer__btn proposal-overview__sort__option ${
              isActive(option) && 'proposal__sort__item--selected'
            } btn__icon__stroke`}
            btnSelector={`proposal-sort-${option}`}
            onClick={() => handleClick(option)}
          >
            <FormattedMessage id={`proposals.overview.sort.${option}`} />
            {isActive(option) && <SortCheckmarkIcon />}
          </Button>
          {index !== sortOptions.length - 1 && (
            <div className="proposal-overview__actions__divider" />
          )}
        </React.Fragment>
      ))}
    </>
  );
};
