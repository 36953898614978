import acceptedIcon from 'assets/icons/icon-accepted-12.svg';
import pendingIcon from 'assets/icons/icon-proposal-pending-12.svg';
import rejectedIcon from 'assets/icons/icon-proposal-rejected-12.svg';
import { useScrollIntoView } from 'modules/authentication';
import { Proposal, useProposals } from 'modules/proposals';
import React, { useCallback } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select, { SingleValue } from 'react-select';
import { FieldWrapper } from 'shared/components';
import { getSelectStyles } from './select.styles';

interface Props {
  proposal?: Proposal;
}

export const AcceptanceStatusSelect: React.FC<Props> = ({ proposal }) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();
  const { updateEntityAsync } = useProposals();

  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<PublishStatusAware<Proposal>>();

  const acceptanceList: Array<{
    label: React.ReactNode;
    value: AcceptanceStatus;
  }> = [
    {
      value: 'pending',
      label: (
        <div className="f f--align-center">
          <img src={pendingIcon} alt="PendingIcon" />
          <p className="s-left--sml s-right--sml t-text-1 text--xsm">Pending</p>
        </div>
      ),
    },
    {
      value: 'accepted',
      label: (
        <div className="f f--align-center">
          <img src={acceptedIcon} alt="AcceptedIcon" />
          <p className="s-left--sml s-right--sml t-text-1 text--xsm">
            Accepted
          </p>
        </div>
      ),
    },
    {
      value: 'rejected',
      label: (
        <div className="f f--align-center">
          <img src={rejectedIcon} alt="RejectedIcon" />
          <p className="s-left--sml s-right--sml t-text-1 text--xsm">
            Rejected
          </p>
        </div>
      ),
    },
  ];

  const handleAcceptanceStatusChange = useCallback(
    (
        field: ControllerRenderProps<
          PublishStatusAware<Proposal>,
          'acceptanceStatus'
        >,
      ) =>
      async (
        selectedOption: SingleValue<{
          value: AcceptanceStatus | undefined;
          label: React.ReactNode;
        }>,
      ) => {
        if (!proposal) return;

        const updatedValue = selectedOption?.value || 'pending';
        const { id, ...rest } = new Proposal({
          ...proposal,
          acceptanceStatus: updatedValue,
        });
        if (proposal.acceptanceStatus !== updatedValue && id) {
          await updateEntityAsync(id, rest);
          field.onChange(updatedValue);
        }
      },
    [proposal, updateEntityAsync],
  );

  function getValue(value: AcceptanceStatus | null) {
    const item = acceptanceList.find((item) => item.value === value);

    return {
      value: item?.value,
      label: item?.label,
    };
  }

  return (
    <FieldWrapper
      name="acceptanceStatus"
      errors={errors}
      containerClassName="proposal-item__acceptance-status__container"
    >
      <Controller
        name="acceptanceStatus"
        control={control}
        defaultValue={getValues('acceptanceStatus') || 'pending'}
        render={({ field }) => (
          <Select
            {...field}
            options={acceptanceList}
            onChange={handleAcceptanceStatusChange(field)}
            value={getValue(field.value)}
            onFocus={handleFocus}
            isSearchable={false}
            placeholder={formatMessage({
              id: 'dropdowns.acceptance.placeholder',
            })}
            className="proposal-item__acceptance-status"
            styles={{
              ...getSelectStyles(),
              indicatorsContainer: (provided) => ({
                ...provided,
                position: 'absolute',
                right: '0',
                top: '0',
                svg: {
                  width: '12px',
                  height: '12px',
                },
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                padding: '6px',
              }),
            }}
          />
        )}
      />
    </FieldWrapper>
  );
};
