import { useLocation } from '@reach/router';
import { UseFormSetError } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getServiceDescriptionText } from 'shared/utils';
import { proposalFilterStates } from '../const';
import { isServiceSegment, isTextSegment } from '../type-guards';
import { shouldSkipValidityCheck } from '../utils';

export function useProposalValidity(
  setError: UseFormSetError<PublishStatusAware<Proposal>>,
) {
  const { formatMessage } = useIntl();
  const { pathname }: Record<'pathname', string> = useLocation();

  const isOnDashboardProposalLocation = proposalFilterStates.some((state) =>
    pathname.includes(state),
  );

  function isProposalValid(data: PublishStatusAware<Proposal>) {
    const { segments, nextPublishStatus, publishStatus } = data;

    const isNextPublishStatusArchivedOrDeleted =
      nextPublishStatus === 'archived' || nextPublishStatus === 'deleted';

    if (isNextPublishStatusArchivedOrDeleted) {
      return true;
    }

    let isProposalValid = true;

    const isValidityCheckSkipped = shouldSkipValidityCheck(
      publishStatus,
      nextPublishStatus,
    );
    const isNextPublishStatusUnpublished =
      (publishStatus === 'published' && nextPublishStatus === 'draft') ||
      (publishStatus === 'archived' && nextPublishStatus === 'draft');
    if (
      isValidityCheckSkipped &&
      isProposalValid &&
      isNextPublishStatusUnpublished
    ) {
      return true;
    }

    const isProposalDraftOrArchived =
      (publishStatus === 'draft' || publishStatus === 'archived') &&
      nextPublishStatus !== 'published';

    if (!!segments?.length && !isProposalDraftOrArchived) {
      segments.forEach((segment, index) => {
        if (isTextSegment(segment)) {
          const { content } = segment;
          const textContent = getServiceDescriptionText(content);
          if (!textContent.trim() && nextPublishStatus === 'published') {
            !isOnDashboardProposalLocation &&
              setError(`segments.${index}.content`, {
                message: formatMessage({
                  id: 'inputs.rich-text.required',
                }),
                type: 'required',
              });
            isProposalValid = false;
          }
        }
        if (isServiceSegment(segment)) {
          const { content } = segment;
          const textContent = getServiceDescriptionText(content);
          if (!textContent.trim() && nextPublishStatus === 'published') {
            !isOnDashboardProposalLocation &&
              setError(`segments.${index}.content`, {
                message: formatMessage({
                  id: 'inputs.service-description.required',
                }),
                type: 'required',
              });
            isProposalValid = false;
          }
        }
      });
    }

    return isProposalValid;
  }

  return { isProposalValid };
}
