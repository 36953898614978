import { FirestoreError } from 'firebase/firestore';
import { useOrganizationIdSelector } from 'modules/authorization';
import { useFirestoreEntitites } from 'modules/firebase';
import { toast } from 'sonner';

export function useServices() {
  const organizationId = useOrganizationIdSelector();

  const entityFunctions = useFirestoreEntitites<Service>(
    'services',
    organizationId,
  );

  async function createEntityAsync(data: Omit<Service, 'id'>) {
    try {
      const documentReference = await entityFunctions.createEntityAsync(data);
      if (documentReference instanceof FirestoreError) throw FirestoreError;
      toast.success(`Service ${data.name} has been successfully added.`);
      return documentReference.id;
    } catch (error) {
      toast.error(
        `We couldn't save the service with name ${data.name}. Try again later.`,
      );
      return;
    }
  }

  async function updateEntityAsync(
    entityId: string,
    data: Omit<Service, 'id'>,
  ) {
    try {
      await entityFunctions.updateEntityAsync(entityId, data);
      toast.success(
        `Service with name ${data.name} has successfully been updated.`,
      );
    } catch (error) {
      toast.error(
        `We couldn't update the service with name ${data.name}. Try again later.`,
      );
    }
  }

  async function deleteEntityAsync(entityId: string) {
    try {
      await entityFunctions.deleteEntityAsync(entityId);
      toast.success(`Service has successfully been deleted.`);
    } catch (error) {
      toast.error(`We couldn't delete the service. Try again later.`);
    }
  }

  return {
    ...entityFunctions,
    createEntityAsync,
    updateEntityAsync,
    deleteEntityAsync,
  };
}
