import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  index: number;
  type: ProposalSegment['type'];
  className: string;
  tabIndex?: number;
  scrollToActiveSection?: (index: number, type: ProposalSegment['type']) => void;
}

export const TableOfContentsListItem: React.FC<Props> = ({
  index,
  type,
  className,
  tabIndex,
  scrollToActiveSection,
  children,
}) => {
  function handleEnter(e: React.KeyboardEvent<HTMLLIElement>) {
    if (e.key === 'Enter') scrollToActiveSection?.(index, type);
  }

  return (
    <li
      role="button"
      onClick={() => scrollToActiveSection?.(index, type)}
      onKeyDown={handleEnter}
      className={className}
      tabIndex={tabIndex}
    >
      {children}
    </li>
  );
};
