import { useParams } from '@reach/router';
import previewIconMenu from 'assets/icons/icon-eye-12-negative.svg';
import previewIconBtn from 'assets/icons/icon-eye-12.svg';
import { useOrganizationIdSelector } from 'modules/authorization';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDomain } from 'shared/hooks';

interface Props {
  className?: string;
  btnSelector?: string;
  isDrawerBtn?: boolean;
  isEditorActions?: boolean;
}

export const PreviewButton: React.FC<Props> = ({
  className,
  btnSelector,
  isDrawerBtn,
  isEditorActions,
}) => {
  const domain = useDomain();
  const params = useParams();
  const { getValues } = useFormContext<Proposal>();
  const organizationId = useOrganizationIdSelector();

  const { id } = getValues();

  const url = `${domain}/s/${organizationId}/proposal/${params.id || id}`;

  return (
    <a href={url} target="_blank" className={className} data-cy={btnSelector}>
      {isDrawerBtn ? (
        <img
          src={previewIconMenu}
          alt="PreviewIcon"
          className={`btn__icon ${!isEditorActions ? 'btn__icon__left' : ''}`}
        />
      ) : (
        <img
          src={previewIconBtn}
          alt="PreviewIcon"
          className={`btn__icon ${!isEditorActions ? 'btn__icon__left' : ''}`}
        />
      )}
      {!isEditorActions && (
        <span>
          <FormattedMessage id="buttons.preview" />
        </span>
      )}
    </a>
  );
};
