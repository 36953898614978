import React, { memo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FieldWrapper } from 'shared/components';

interface Props {
  index: number;
  control: UseFormReturn<PublishStatusAware<Proposal>>['control'];
  formState: UseFormReturn<PublishStatusAware<Proposal>>['formState'];
  isFieldRequired?: boolean;
  value?: string;
}

export const HeadingTitleInput: React.FC<Props> = memo(
  ({ index, formState: { errors }, isFieldRequired, control, value }) => {
    const { formatMessage } = useIntl();

    return (
      <FieldWrapper
        name={`segments.${index}.title`}
        errors={errors}
        labelId="inputs.heading.label"
        isRequired={isFieldRequired}
      >
        <Controller
          name={`segments.${index}.title`}
          control={control}
          rules={{
            required:
              isFieldRequired &&
              formatMessage({ id: 'inputs.heading.required' }),
          }}
          defaultValue={value || ''}
          render={({ field, fieldState }) => (
            <input
              {...field}
              type="text"
              className={`input input__line proposal-builder-heading__title s-bottom--sml ${
                !!fieldState.error ? 'input__line__error' : ''
              }`}
              placeholder={formatMessage({ id: 'inputs.heading.placeholder' })}
              data-cy={`heading-headline-input${index}`}
            />
          )}
        />
      </FieldWrapper>
    );
  },
  (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
    const hasErrors = !!Object.keys(nextProps.formState.errors);
    return (
      prevProps.formState.isDirty === nextProps.formState.isDirty && !hasErrors
    );
  },
);
