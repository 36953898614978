import {
  selector,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { sortDescendingFromNewest } from 'shared/utils';
import { proposalsAtoms } from './atoms';

/**Proposals selector */
const proposalsSelector = selector({
  key: 'proposalsSelector',
  get: ({ get }) => get(proposalsAtoms.proposals),
  set: ({ get, set }, newValue) =>
    set(proposalsAtoms.proposals, newValue || get(proposalsAtoms.proposals)),
});
export const useProposalsSelector = () => useRecoilValue(proposalsSelector);
export const useSetProposalsSelector = () =>
  useSetRecoilState(proposalsSelector);

/**Proposals loading selector */
export const useProposalsLoadingSelector = () =>
  useRecoilValue(proposalsAtoms.isLoading);

/**Proposals error selector */
export const useProposalsErrorSelector = () =>
  useRecoilValue(proposalsAtoms.error);
export const useSetProposalsErrorSelector = () =>
  useSetRecoilState(proposalsAtoms.error);

/**Proposal custom tags selector */
const proposalCustomTagsSelector = selector({
  key: 'proposalCustomTagsSelector',
  get: ({ get }) => {
    const proposals = get(proposalsAtoms.proposals);
    const allTags = proposals.flatMap((proposal) => proposal.customTags);
    return [...new Set(allTags)];
  },
});
export const useProposalCustomTagsSelector = () =>
  useRecoilValue(proposalCustomTagsSelector);

/**Draft,published,archived and template selectors */
const proposalsByStatusSelector = selectorFamily({
  key: 'proposalsByStatusSelector',
  get:
    (status) =>
    ({ get }) => {
      const proposals = get(proposalsAtoms.proposals);
      return proposals
        ?.filter((item) => item.publishStatus === status)
        .sort(sortDescendingFromNewest);
    },
});
export const useProposalsByStatusSelector = (status: PublishStatus) =>
  useRecoilValue(proposalsByStatusSelector(status));

/**Global search by query selector */
type ProposalsQueryParams = {
  query: string;
  searchByClient?: boolean;
};

const proposalsByQuerySelector = selectorFamily({
  key: 'proposalsByQuerySelector',
  get:
    ({ query, searchByClient }: ProposalsQueryParams) =>
    ({ get }) => {
      if (!query || typeof query !== 'string') return [];
      const proposals = get(proposalsAtoms.proposals);

      return proposals
        ?.filter(({ name, client }) =>
          `${name} ${searchByClient ? client?.name : ''}`
            .toLocaleLowerCase()
            .includes(query.toLowerCase()),
        )
        .sort(sortDescendingFromNewest);
    },
});
export const useProposalsByQuerySelector = (
  query: string,
  searchByClient: boolean,
) => useRecoilValue(proposalsByQuerySelector({ query, searchByClient }));

/**Proposal by id selector */
const proposalByIdSelector = selectorFamily({
  key: 'proposalByIdSelector',
  get:
    (proposalId) =>
    ({ get }) => {
      const proposals = get(proposalsSelector);
      return proposals?.find((item) => item.id === proposalId);
    },
});
export const useProposalByIdSelector = (proposalId?: string) =>
  useRecoilValue(proposalByIdSelector(proposalId));

/**Client email selector */
const clientEmailSelector = selector({
  key: 'clientEmailSelector',
  get: ({ get }) => get(proposalsAtoms.clientEmail),
  set: ({ get, set }, newValue) =>
    set(
      proposalsAtoms.clientEmail,
      newValue || get(proposalsAtoms.clientEmail),
    ),
});
export const useClientEmailSelector = () => useRecoilValue(clientEmailSelector);
export const useSetClientEmailSelector = () =>
  useSetRecoilState(clientEmailSelector);

/**Files to remove selector */
const filesToRemoveSelector = selector({
  key: 'filesToRemoveSelector',
  get: ({ get }) => get(proposalsAtoms.filesToRemove),
  set: ({ get, set }, newValue) =>
    set(
      proposalsAtoms.filesToRemove,
      newValue || get(proposalsAtoms.filesToRemove),
    ),
});
export const useSetFilesToRemoveSelector = () =>
  useSetRecoilState(filesToRemoveSelector);
export const useFilesToRemoveState = () =>
  useRecoilState(filesToRemoveSelector);

type NumberOfSentProposalsParams = {
  clientId?: string;
};

// Selector family to count the number of proposals sent to a specific client
const numberOfSentProposalsSelector = selectorFamily({
  key: 'numberOfSentProposalsSelector',
  get:
    ({ clientId }: NumberOfSentProposalsParams) =>
    ({ get }) => {
      const proposals = get(proposalsAtoms.proposals);
      return proposals?.filter(
        (proposal) =>
          proposal.client?.id === clientId &&
          proposal?.publishStatus === 'published',
      )?.length;
    },
});

export const useNumberOfSentProposalsSelector = (clientId?: string) =>
  useRecoilValue(numberOfSentProposalsSelector({ clientId }));

type ProposalExpiredParams = {
  publishStatus: PublishStatus;
  acceptanceStatus: AcceptanceStatus;
  validUntilDateTime: number;
};

// Selector family to check if a proposal is expired
const proposalExpiredSelector = selectorFamily({
  key: 'proposalExpiredSelector',
  get:
    ({
      publishStatus,
      acceptanceStatus,
      validUntilDateTime,
    }: ProposalExpiredParams) =>
    () => {
      return (
        publishStatus === 'published' &&
        acceptanceStatus === 'pending' &&
        validUntilDateTime < +new Date()
      );
    },
});

export const useProposalExpiredSelector = (
  proposal?: Proposal,
  isSubscribed?: boolean,
) => {
  if (!isSubscribed || !proposal) return false;
  const { publishStatus, acceptanceStatus, validUntilDateTime } = proposal;

  return useRecoilValue(
    proposalExpiredSelector({
      publishStatus,
      acceptanceStatus,
      validUntilDateTime,
    }),
  );
};

/**Date constant of release of new dashboard facelift where we're introducing limits on published proposals.
 * This is used to allow old proposals to be published/unpublished without any limits.
 */
export const FACELIFT_RELEASE_DATE = 1709625600000;

export const useHasOldProposalsSelector = () => {
  const proposals = useRecoilValue(proposalsSelector);
  return proposals?.some(
    (proposal) => proposal.creationDateTime < FACELIFT_RELEASE_DATE,
  );
};
