import { navigate } from 'gatsby';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { toast } from 'sonner';
import { subscriptionAtoms } from '../state';
import { useStripeCheckout } from './checkout';
import { useStripeCustomer } from './customer';
import { useStripeSubscription } from './subscription';

export function useSubscriptionCheckout() {
  const [loading, setLoading] = useState(false);
  const { createStripeCustomer } = useStripeCustomer();

  const customerId = useRecoilValue(subscriptionAtoms.customerId);

  const { createBillingPortal } = useStripeCheckout();
  const { createStripeSubscription } = useStripeSubscription();

  async function handleRedirectToBillingPortal(customerId: string) {
    const billingPortalData = await createBillingPortal(customerId);

    if (!billingPortalData || !billingPortalData.url) {
      toast.error(
        'Sorry, the creation of your checkout failed. Please try again later or contact support@propoze.app for assistance.',
      );
      return;
    }

    navigate(billingPortalData.url);
    return;
  }

  async function handleNewCustomer() {
    const customerId = await createStripeCustomer();
    if (!customerId) throw new Error('No customer id');

    await createStripeSubscription(process.env.GATSBY_FREE_PLAN_ID || '', customerId);
    return customerId;
  }

  async function handleCheckout() {
    try {
      setLoading(true);
      if (!customerId) {
        const newCustomerId = await handleNewCustomer();
        await handleRedirectToBillingPortal(newCustomerId);
        return;
      }

      await handleRedirectToBillingPortal(customerId);
      return;
    } catch (error) {
      toast.error(
        'Sorry, the creation of your checkout failed. Please try again later or contact support.',
      );
    }
  }

  return {
    loading,
    handleCheckout,
  };
}
