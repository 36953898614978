import { richTextListTypes } from 'modules/editor';
import { CustomEditor } from 'shared/components';
import { Descendant, Editor, Element as SlateElement, Transforms } from 'slate';

export function isListItem(element?: Descendant): element is ListItemElement {
  return (element as ListItemElement).type === 'li';
}

export function isBlockActive(
  editor: CustomEditor,
  variant: RichTextElementVariant | RichTextLeafVariant,
) {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === variant,
    }),
  );

  return !!match;
}

export function isMarkActive(
  editor: CustomEditor,
  variant: RichTextElementVariant | RichTextLeafVariant,
) {
  const marks = Editor.marks(editor);
  return marks ? marks[variant] === true : false;
}

export function toggleMark(
  editor: CustomEditor,
  variant: RichTextElementVariant | RichTextLeafVariant,
) {
  const isActive = isMarkActive(editor, variant);

  isActive
    ? Editor.removeMark(editor, variant)
    : Editor.addMark(editor, variant, true);
}

export function toggleBlock(
  editor: CustomEditor,
  variant: RichTextElementVariant | RichTextLeafVariant,
) {
  const isActive = isBlockActive(editor, variant);
  const isList = richTextListTypes.includes(variant);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      richTextListTypes.includes(n.type),
    split: true,
  });
  let newProperties: Partial<SlateElement> = {
    type: isActive
      ? 'paragraph'
      : isList
      ? 'li'
      : (variant as RichTextElementVariant),
  };

  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    Transforms.wrapNodes(editor, {
      type: variant === 'ul' ? 'ul' : 'ol',
      children: [],
    });
  }
}
