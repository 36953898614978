import { FirebaseError } from 'firebase/app';
import {
    EmailAuthProvider,
    reauthenticateWithCredential,
    signOut,
    updatePassword,
} from 'firebase/auth';
import { auth } from 'modules/firebase';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';

export function useChangePassword(onClose: () => void) {
  const { formatMessage } = useIntl();
  const user = useMemo(() => auth.currentUser, []);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<ChangePasswordData>({
    mode: 'onSubmit',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });
  const { setError, reset } = form;

  async function reauthenticateWithPassword(password: string) {
    if (!user?.email) return;
    const credential = EmailAuthProvider.credential(user?.email, password);
    await reauthenticateWithCredential(user, credential);
  }

  async function changePassword(newPassword: string) {
    if (!user) return;
    await updatePassword(user, newPassword);
  }

  async function onSubmit({
    currentPassword,
    newPassword,
    confirmPassword,
  }: ChangePasswordData) {
    if (newPassword !== confirmPassword) {
      setError('confirmPassword', {
        message: formatMessage({
          id: 'inputs.change-password.errors.passwords-not-match',
        }),
        type: 'manual',
      });
      return;
    }
    if (currentPassword === newPassword) {
      setError('newPassword', {
        type: 'manual',
        message: formatMessage({
          id: 'inputs.change-password.errors.passwords-identical',
        }),
      });
      return;
    }
    setIsLoading(true);
    try {
      await reauthenticateWithPassword(currentPassword);
      await changePassword(newPassword);
      await signOut(auth);
      toast.success(
        'Your password has been successfully changed. Please log in again.',
      );
      reset();
      onClose();
      return;
    } catch (e) {
      if (!(e instanceof FirebaseError)) {
        return toast.error('Something went wrong. Please try again later.');
      }
      if (e.code === 'auth/wrong-password') {
        setError('currentPassword', {
          type: 'manual',
          message: formatMessage({
            id: 'inputs.change-password.errors.wrong-password',
          }),
        });
        return;
      }
      toast.error('Something went wrong. Please try again later.');
      return;
    } finally {
      setIsLoading(false);
    }
  }

  return {
    form,
    isLoading,
    onSubmit,
  };
}
