import React from 'react';
import Icon from 'react-icons-kit';
import {
  isBlockActive,
  isMarkActive,
  toggleBlock,
  toggleMark,
} from 'shared/utils';
import { useSlateStatic } from 'slate-react';
import { Button } from '../button';

interface Props {
  variant: RichTextElementVariant | RichTextLeafVariant;
  icon: string;
}

export const RichTextToolbarButton: React.FC<Props> = ({ variant, icon }) => {
  const isMark = ['bold', 'italic', 'underline', 'strikethrough'].includes(
    variant,
  );
  const editor = useSlateStatic();

  function handleMouseDown(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    isMark ? toggleMark(editor, variant) : toggleBlock(editor, variant);
  }

  const isActive = isMark
    ? isMarkActive(editor, variant)
    : isBlockActive(editor, variant);

  let className = 'slate__toolbar__icon';
  if (isActive) className += ' slate__toolbar__icon--active';

  return (
    <Button type="button" className={className} onMouseDown={handleMouseDown}>
      <Icon icon={icon}>{icon}</Icon>
    </Button>
  );
};
