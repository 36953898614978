import { useParams } from '@reach/router';
import addIcon from 'assets/icons/icon-add-16-secondary.svg';
import deleteIcon from 'assets/icons/icon-trash-16-negative.svg';
import { dashboardSelector } from 'modules/dashboard';
import { firebaseAtoms } from 'modules/firebase';
import { useFilesToRemoveState } from 'modules/proposals';
import React, { memo, useCallback } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { Button, ConfirmationModal } from 'shared/components';
import { useModal } from 'shared/hooks';
import { toast } from 'sonner';

const sizeLimitInBytes = 2 / 10 ** -6;
const accept = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/webp': ['.webp'],
  'image/svg+xml': ['.svg'],
  'image/gif': ['.gif'],
};

interface Props {
  setValue: UseFormReturn<PublishStatusAware<Proposal>>['setValue'];
  getValues: UseFormReturn<PublishStatusAware<Proposal>>['getValues'];
  control: UseFormReturn<PublishStatusAware<Proposal>>['control'];
  formState: UseFormReturn<PublishStatusAware<Proposal>>['formState'];
}

export const CoverImagePicker: React.FC<Props> = memo(
  ({ control, setValue, getValues }) => {
    const { formatMessage } = useIntl();
    const { id } = useParams();

    const { isModalOpen, closeModal, openModal } = useModal();

    const [filesToRemove, setFilesToRemove] = useFilesToRemoveState();
    const [blobUrlCache, setBlobUrlCache] = useRecoilState(
      firebaseAtoms.blobUrlCache,
    );
    const { isDashboardOpen } = useSelector(dashboardSelector.getState);

    function handleSubmit() {
      setFilesToRemove(
        filesToRemove?.length ? [...filesToRemove, 'imageUrl'] : ['imageUrl'],
      );
      setBlobUrlCache((prevBlobUrlCache) => ({
        ...prevBlobUrlCache,
        [`imageUrl?proposalId=${id}`]: null,
      }));
      setValue('imageUrl', null, { shouldDirty: true }), closeModal();
    }

    const onDropAccepted = useCallback(
      (files: File[]) => {
        const fileUrl = URL.createObjectURL(files[0]);
        setBlobUrlCache((prevBlobUrlCache) => ({
          ...prevBlobUrlCache,
          [`imageUrl?proposalId=${id}`]: fileUrl,
        }));
        if (filesToRemove?.includes('imageUrl')) {
          setFilesToRemove(filesToRemove.filter((file) => file !== 'imageUrl'));
        }

        setValue('imageUrl', fileUrl, { shouldDirty: true });
      },
      [setValue, filesToRemove],
    );

    const onDropRejected = useCallback((rejections: FileRejection[]) => {
      const errorCode = rejections[0].errors[0].code;

      switch (errorCode) {
        case 'file-too-large':
          return toast.error(
            formatMessage({ id: `uploaders.errors.fileTooLarge` }, { size: 2 }),
          );
        case 'file-invalid-type':
          return toast.error(
            formatMessage(
              { id: `uploaders.errors.fileInvalidType` },
              { size: 2 },
            ),
          );
        default:
          return toast.error(
            formatMessage({ id: 'uploaders.errors.defaultError' }, { size: 2 }),
          );
      }
    }, []);

    return (
      <>
        <div className="photobox">
          <Controller
            control={control}
            name="imageUrl"
            defaultValue={getValues('imageUrl') || null}
            render={({ field: { ref, ...field } }) => (
              <Dropzone
                {...field}
                accept={accept}
                maxSize={sizeLimitInBytes}
                maxFiles={1}
                disabled={isDashboardOpen}
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <>
                    {getValues('imageUrl') && (
                      <Button
                        type="button"
                        onClick={openModal}
                        style="primary"
                        size="sml"
                        className="photobox__delete"
                        btnSelector="delete-icon-btn"
                      >
                        <img src={deleteIcon} alt="DeleteIcon" />
                      </Button>
                    )}
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      {getValues('imageUrl') ? (
                        <div
                          className="dropzone__image"
                          style={{
                            backgroundImage: `url(${
                              blobUrlCache[`imageUrl?proposalId=${id}`] ||
                              getValues('imageUrl')
                            })`,
                          }}
                        />
                      ) : isDragActive ? (
                        <p>
                          <FormattedMessage id="uploaders.cover-image.drag" />
                        </p>
                      ) : (
                        <>
                          <div className="d--desktop--only">
                            <div
                              className="dropzone__uploadmessage"
                              data-cy="builder-cover-image"
                            >
                              <img src={addIcon} alt="AddIcon" />
                              <p>
                                <FormattedMessage id="uploaders.cover-image.click" />
                              </p>
                            </div>
                          </div>
                          <div className="d--mobile--only">
                            <div className="dropzone__uploadmessage--mobile">
                              <img src={addIcon} alt="AddIcon" />
                              <p>
                                <FormattedMessage id="uploaders.cover-image.click-mobile" />
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {!getValues('imageUrl') && (
                      <p className="field__helptext t-tiny">
                        {formatMessage({ id: 'uploaders.cover-image.help' })}
                      </p>
                    )}
                  </>
                )}
              </Dropzone>
            )}
          />
        </div>
        {isModalOpen && (
          <ConfirmationModal
            onClose={closeModal}
            onSubmit={handleSubmit}
            title={formatMessage({
              id: 'uploaders.cover-image.remove-modal.title',
            })}
            subtitle={formatMessage({
              id: 'uploaders.cover-image.remove-modal.subtitle',
            })}
            confirmationButtonLabel={formatMessage({
              id: 'buttons.delete',
            })}
            declineButtonLabel={formatMessage({
              id: 'buttons.cancel',
            })}
            type="delete"
          />
        )}
      </>
    );
  },
  (prevProps: Readonly<Props>, nextProps: Readonly<Props>) =>
    prevProps.formState.isDirty === nextProps.formState.isDirty,
);
