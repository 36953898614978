import { HttpsCallable } from 'firebase/functions';
import { atom } from 'recoil';

const callables = atom<Partial<Record<FirebaseFunctions, HttpsCallable>>>({
  default: {},
  key: 'firebase-callables',
});

const blobUrlCache = atom<Map<string, string>>({
  default: new Map(),
  key: 'firebase-blob-url-cache',
});

export const firebaseAtoms = {
  blobUrlCache,
  callables,
};
