import { useEffect, useMemo } from 'react';
import { useMobile } from 'shared/hooks';

export function useFreezeOverlayScroll(isModalVisible: boolean) {
  const bodyElement = useMemo(
    () => document.getElementsByTagName('body')[0],
    [],
  );
  const isMobile = useMobile();

  useEffect(() => {
    isModalVisible && isMobile
      ? (bodyElement.style.overflow = 'hidden')
      : (bodyElement.style.overflow = 'unset');

    return () => {
      bodyElement.style.overflow = 'unset';
    };
  }, [isModalVisible]);
}
