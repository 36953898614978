import { doc, onSnapshot } from 'firebase/firestore';
import { authenticationSelector } from 'modules/authentication';
import { useOrganizationIdSelector } from 'modules/authorization';
import { db } from 'modules/firebase';
import {
    accessListFirestoreConverter,
    proposalsAtoms,
} from 'modules/proposals';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { toast } from 'sonner';

export function useProposalAccessListListener(proposalId: string) {
  const { formatMessage } = useIntl();

  const organizationId = useOrganizationIdSelector();
  const { user } = useSelector(authenticationSelector.getState);
  const setProtectedAccessList = useSetRecoilState(proposalsAtoms.accessList);
  const [loading, setLoading] = useRecoilState(
    proposalsAtoms.loadingAccessList,
  );

  useEffect(() => {
    if (!user || !organizationId) return;

    const document = doc(
      db,
      `${organizationId}/proposals/access-rights/${proposalId}`,
    ).withConverter(accessListFirestoreConverter);

    return onSnapshot(
      document,
      (snapshot) => {
        setProtectedAccessList(snapshot?.data() || []);
        setLoading(false);
      },
      () => {
        toast.error(formatMessage({ id: 'protected-access-list.api.error' }));
        setProtectedAccessList([]);
        setLoading(false);
      },
    );
  }, [proposalId, organizationId]);

  return {
    loading,
  };
}
