import { atom } from 'recoil';
import { AccessList } from '../components';

const proposals = atom<Proposal[]>({
  key: 'proposals',
  default: [],
});

const isLoading = atom<boolean>({
  key: 'proposals-loading',
  default: true,
});

const error = atom<string | undefined>({
  key: 'proposals-error',
  default: undefined,
});

const loadingAccessList = atom<boolean>({
  key: 'protected-access-list-loading',
  default: true,
});

const accessList = atom<AccessList[]>({
  key: 'protected-access-list',
  default: [],
});

const accessLoading = atom<boolean>({
  key: 'protected-access-working',
  default: false,
});

const clientEmail = atom<string>({
  key: 'client-email',
  default: '',
});

const filesToRemove = atom<string[]>({
  key: 'files-to-remove',
  default: [],
});

export const proposalsAtoms = {
  proposals,
  isLoading,
  error,
  accessList,
  loadingAccessList,
  accessLoading,
  clientEmail,
  filesToRemove,
};
